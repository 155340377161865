import React, {Fragment, useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import axios from "../../axiosInstance";
import Loader from "./Loader";
import HeaderMenuItemsInner from "./HeaderMenuItemsInner";
import {API_URL} from "../../constant";

export default function HeaderDesktopMenu( { anonymousMode = false } ) {
    const { t } = useTranslation();
    const [isRequest, setIsRequest] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeMenuData, setActiveMenuData] = useState([]);
    const [markdownMenuData, setMarkdownMenuData] = useState([]);
    const [activeCategory, setActiveCategory] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const menuData = useSelector(state => state.getDirectionsData.menuData, shallowEqual);
    const hasActions = useSelector(state => state.hasActions.hasActions, shallowEqual);
    const menuDataWithoutMarkdown = menuData.filter((item) => item.slug !== 'markdown');
    let location = useLocation();
    const selectedPartner = localStorage.getItem('selectedGlobalPartner');
    const [customerId] = useState(selectedPartner && JSON.parse(selectedPartner).id);

    useEffect(() => {
        isMenuOpen && setIsMenuOpen(false)
    }, [location]);

    function getMarkDown() {
        setIsRequest(true);

        axios.get(`${API_URL}/front/direction/markdown?customer_id=${customerId}`)
            .then(res => {
                setIsRequest(false);
                setActiveMenuData([res.data]);
                setMarkdownMenuData([res.data]);
            })
            .catch((error) => {
                console.log(error);
                setIsRequest(false);
            });
    }

    return (
        <>
            <div id="hover-menu-top" className={`bg-light ${anonymousMode? 'anonymous-menu' : ''}`}>
                <div className="page-container">
                    <div className="header-nav d-flex flex-wrap">
                        {menuData.length > 0 && menuData.map((item, w) => {
                            if(item.slug !== 'markdown') {
                                return (
                                    <button key={`btn-catalog`} type="button"
                                            className={`menu-catalog-btn d-flex align-items-center semi-bold ${isMenuOpen && activeCategory === 'catalog' ? 'open' : ''}`}
                                            onMouseEnter={() => {
                                                setIsMenuOpen(true);
                                                setActiveCategory('catalog');
                                                setActiveMenuData(menuDataWithoutMarkdown);
                                            }}
                                    >
                                        <svg className="svg-icon mr-3">
                                            <use xlinkHref="/images/sprite.svg#burger-menu"></use>
                                        </svg>
                                        {t('catalogProducts')}
                                    </button>
                                )
                            }   else {
                                return (
                                    <button key={`btn-markdown`} type="button"
                                            className={`menu-catalog-btn d-flex align-items-center semi-bold ${isMenuOpen && activeCategory === 'markdown' ? 'open' : ''}`}
                                            onMouseEnter={() => {
                                                setIsMenuOpen(true);
                                                setActiveCategory('markdown');
                                                if(markdownMenuData.length > 0) {
                                                    setActiveMenuData(markdownMenuData);
                                                } else {
                                                    !isRequest && getMarkDown();
                                                }
                                            }}
                                    >
                                        {t('markdownWord')}
                                    </button>
                                )
                            }
                        })}
                        <Link to="/brands" className="no-decoration semi-bold">{t('brandsWord')}</Link>
                        {hasActions &&
                            <Link to="/actions" className="no-decoration semi-bold">{t('actionsWord')}</Link>
                        }
                    </div>
                </div>
            </div>
            <div id="hover-menu-bg"
                 className={`position-fixed ${isMenuOpen ? 'show-hover-menu-bg' : ''}`}
                 onMouseEnter={() => setIsMenuOpen(false)}
            ></div>
            <div className={`hover-menu d-none d-md-flex align-items-stretch position-absolute ${isMenuOpen ? 'show-hover-menu' : ''} ${anonymousMode? 'anonymous-menu' : ''}`}
                 onMouseLeave={() => setIsMenuOpen(false)}
            >
                <div className="categories-menu flex-shrink-0 bg-white position-relative">
                    {isRequest && <Loader transparent />}
                    {activeMenuData.length > 0 && activeMenuData.map((category, i) => {
                        const isMarkdown = category.slug === 'markdown' ? `markdown/` : '';
                        if(activeCategory === 'catalog' && category.slug === 'markdown') return;

                        return (
                            <Fragment key={`category-${i}`}>
                                <Link to={`/direction/${category.slug}`}
                                      className="categories-menu__main-item text-decoration-none semi-bold"
                                      onMouseOver={() => setActiveTab('')}
                                >
                                    {category.name}
                                </Link>
                                <div className="categories-menu__sub-categories">
                                    {category.children.length > 0 && category.children.map((subCategory, j) => {
                                        return (
                                            <Link key={`subcategory-${i}-${j}`}
                                                  to={`/section/${isMarkdown}${subCategory.slug}`}
                                                  className={`categories-menu__sub-item text-decoration-none ${activeTab == subCategory.id ? 'active' : ''}`}
                                                  onMouseOver={() => setActiveTab(subCategory.id)}
                                            >
                                                {subCategory.name}
                                                <svg className="svg-icon">
                                                    <use xlinkHref="/images/sprite.svg#arrow-icon"></use>
                                                </svg>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
                {activeTab !== '' &&
                    <HeaderMenuItemsInner menuData={activeMenuData} currentTab={activeTab}/>
                }
            </div>
        </>
    );
}