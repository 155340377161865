import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {DEFAULT_LOCALE} from "../../constant";

export default function LangDropdown({thisLocale, isLangUpdated}) {
    const { i18n } = useTranslation();
    const [locale, setLocale] = useState(localStorage.getItem('userLocale') || DEFAULT_LOCALE);

    function changeLocale(locale) {
        if(localStorage.getItem('userLocale') === locale) return;

        i18n.changeLanguage(locale);
        localStorage.setItem('userLocale', locale);
        setLocale(locale);

        isLangUpdated && isLangUpdated(true);
    }

    return (
        <div className="lang-dropdown dropdown ml-md-1 mr-md-2 my-auto">
            <div className="lang-dropdown__btn pointer" data-toggle="dropdown">
                {locale === 'ru' ? 'RU' : 'UA'}
                <div className="custom-dropdown__arrow position-absolute"></div>
            </div>
            <div className="lang-dropdown__list dropdown-menu">
                <div className="lang-dropdown__list-item position-relative pointer"
                     onClick={() => changeLocale(locale === 'ru' ? 'ua' : 'ru')}
                >
                    {locale === 'ru' ? 'UA' : 'RU'}
                </div>
            </div>
        </div>
    );
}