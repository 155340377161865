import React, {useEffect, useRef, useState} from "react";
import {
    getCartSelection, getDirections,
    getSelectedCustomerProjects,
    updateSelectedPartner, updateSelectedProject, updateSelectedSelection
} from "../../../../actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import SelectionsDropdown from "./SelectionsDropdown";
import {isAuthorizedUser, useOutsideClick} from "../../../../helper";
import {useTranslation} from "react-i18next";
import LoaderBtn from "../../../partials/LoaderBtn";
import { FixedSizeList } from 'react-window';
import {CUSTOMER_USER_TYPE} from "../../../../constant";

export default function PartnerDropdown({ type, currentCabinetPartner, selected }) {
    const { t,i18n } = useTranslation();
    const placeholder = type ==='header' ? 'choicePartner' : 'allPartners';
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [partners, setPartners] = useState([]);
    const [allPartners, setAllPartners] = useState([]);
    const [partnerInput, setPartnerInput] = useState(t(placeholder));
    const [currentPartner, setCurrentPartner] = useState('');
    const [currentProjects, setCurrentProjects] = useState([]);
    const [currentUserType, setCurrentUserType] = useState('1');
    const isCustomersRequest = useSelector(state => state.customers.customersLoader, shallowEqual);
    const customers = useSelector(state => state.customers.customers, shallowEqual);
    const selectedCustomerProjects = useSelector(state => state.selectedOptions.globalPartnerProjects, shallowEqual);
    const selectedGlobalPartner = useSelector(state => state.selectedOptions.globalPartner, shallowEqual);
    const managerId = useSelector(state => state.getManagerId.managerId, shallowEqual);
    const dispatch = useDispatch();
    const partnerListRef = useRef();
    const dropdownListHeight = (type !=='header' && type !=='duplicate') ? 125 : 150;
    const dropdownRowHeight = 32;

    const PartnerNameRow = ({ index, style }) => {
        const partner = partners[index];
        const partnerName = partner.name.replace(new RegExp(partnerInput, 'gi'), function(str) {
            return `<span class="semi-bold">${str}</span>`
        })

        return (
            <li key={`custom-item-${index}`}
                className={currentPartner === partner.name ? 'selected-filter' : ''}
                title={partner.name}
                style={style}
                onClick={() => {
                    setPartnerInput(partner.name);
                    currentPartner != partner.name && selectPartner(partner);
                }}
                dangerouslySetInnerHTML={{ __html: partnerName }}
            />
        );
    };

    useEffect(() => {
        // console.log(1, 'setCurrentUserType', localStorage.getItem('userType'))
        setCurrentUserType(localStorage.getItem('userType'));
    },[]);

    useEffect(() => {
        setPartnerInput(currentPartner == '' ? t(placeholder) : currentPartner)
    }, [i18n.language]);

    useEffect(() => {
        setCurrentPartner(currentCabinetPartner ? currentCabinetPartner : '');

        if (currentCabinetPartner) {
            setPartnerInput(currentCabinetPartner);
        }
    }, [currentCabinetPartner]);

    useEffect(() => {
        setCurrentProjects(selectedCustomerProjects);
    }, [selectedCustomerProjects]);

    useEffect(() => {
        // console.log(2, 'customers set in partneers', customers)
        setPartners(customers);
        setAllPartners(customers);
    }, [customers]);
 
    useEffect(() => {
        type === 'header' && dispatch(getSelectedCustomerProjects(selectedGlobalPartner.id, managerId));

        if((type === 'header' || type === 'duplicate') && selectedGlobalPartner.name !== undefined) {
            setPartnerInput(selectedGlobalPartner.name);
            setCurrentPartner(selectedGlobalPartner.name);
        }
    }, [selectedGlobalPartner]);

    // Для пользователя партнера всегда должен быть выбран партнер!!!
    useEffect(() => {
        // console.log(3,'parther changed', partners, localStorage.getItem('userType'))
        if (partners.length > 0 && localStorage.getItem('userType') === CUSTOMER_USER_TYPE && currentPartner === '') {
            selectPartner(partners[0]);
        }
    }, [partners])

    useOutsideClick(partnerListRef, () => {
        partnerInput === '' && setPartnerInput(currentPartner);
        setIsOpenDropdown(false);

        allPartners.map(item => {
            if(partnerInput !== item.name) {
                setPartnerInput(currentPartner == '' ? t(placeholder) : currentPartner);
                setPartners(allPartners);
            }
        })
    });

    function selectPartner(partner) {
        // console.log(4,'select partner', partner)
        setPartners(allPartners);
        setIsOpenDropdown(false);

        if(type === 'duplicate') {
            setCurrentPartner(partner.name);
        }

        if(type === 'header') {
            // console.log(5,'select partner in header', partner)
            setCurrentPartner(partner.name);

            dispatch(updateSelectedPartner({id: partner.id, name: partner.name}));
            dispatch(updateSelectedProject({}));
            dispatch(updateSelectedSelection({}));
            dispatch(getCartSelection());
            dispatch(getDirections(partner.id));
        } else {
            if(partner) {
                selected({page: 1, id: partner.id, name: partner.name});
            } else {
                selected();
            }
        }
    }

    function partnersSearch(value) {
        const searchResult = allPartners.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

        setPartners(searchResult);
        setPartnerInput(value);
    }

    function resetPartner(e) {
        e.stopPropagation();

        if(type === 'header') {
            setCurrentPartner(t(placeholder));
            setPartnerInput(t(placeholder));
            dispatch(updateSelectedPartner({}));
            dispatch(getCartSelection());
            dispatch(getDirections());
        } else {
            setCurrentPartner(t(placeholder));
            setPartnerInput(t(placeholder));
            selected();
        }
    }

    return (
        <>
            <div ref={partnerListRef}
                 className="custom-dropdown position-relative"
                 onClick={() => !isOpenDropdown && setIsOpenDropdown(true)}
            >
                <div className="custom-dropdown__btn floating-label position-relative m-0 pointer">
                    {(currentUserType !== CUSTOMER_USER_TYPE && (partnerInput !== '') && (partnerInput !== t(placeholder))) &&
                        <div className="reset-btn__wrapper position-absolute h-100 p-2"
                             onClick={resetPartner}
                        >
                            <svg className="reset-btn svg-icon reset-btn--static">
                                <use xlinkHref="/images/sprite.svg#close"></use>
                            </svg>
                        </div>
                    }
                    <input id={`partner-input-${type}`}
                           className={`w-100 pointer text-ellipsis ${partnerInput !== '' ? 'has-value' : ''} ${partnerInput === t(placeholder) ? 'default-padding' : ''}`}
                           type="text"
                           value={partnerInput}
                           onChange={e => partnersSearch(e.target.value)}
                           onFocus={() => setPartnerInput('')}
                           autoComplete="off"
                    />
                    <label htmlFor={`partner-input-${type}`} className="text-gray-30 position-absolute mb-0">
                        {t(type === 'header' ? 'partnerWord' : 'choicePartner')}
                    </label>
                    <div className="custom-dropdown__arrow position-absolute"></div>
                </div>
                {isOpenDropdown &&
                    <ul className={`custom-dropdown__list custom-scroll position-absolute w-100`}>
                        {isCustomersRequest &&
                            <LoaderBtn bgColor={'#fff'} loaderBlue={true}/>
                        }
                        {(type !=='header' && type !=='duplicate') &&
                            <div className={`custom-dropdown__item pointer ${currentPartner == t(placeholder) ? 'selected-filter' : ''}`}
                                 title={t(placeholder)}
                                 onClick={() => {
                                     setPartnerInput(t(placeholder));
                                     currentPartner != t(placeholder) && selectPartner()
                                 }}
                            >
                                {t(placeholder)}
                            </div>
                        }
                        {partners.length > 0 ?
                            <FixedSizeList
                                className="custom-scroll"
                                height={partners.length > 5 ? dropdownListHeight : partners.length * dropdownRowHeight}
                                width="100%"
                                itemCount={partners.length}
                                itemSize={dropdownRowHeight} // height of one row
                            >
                                {PartnerNameRow}
                            </FixedSizeList>
                            :
                            <li className="no-partners">{t('nothingFound')}</li>
                        }
                    </ul>
                }
            </div>
            {type === 'header' &&
                <SelectionsDropdown type="header" projectsData={currentProjects}/>
            }
        </>
    );
}

