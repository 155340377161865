import React, {useEffect, useState} from "react";
import {useDebouncedEffect, inputRestriction} from "../../../../helper";
import {getCartSelection, UPDATE_CART_SELECTION_COUNTER} from "../../../../actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import CustomToastMessage from "../../../partials/CustomToastMessage";
import {API_URL} from "../../../../constant";
import axios from '../../../../axiosInstance';

export default function QuantityInput({ quantity, maxQuantity, productId, selectionId, projectId, disabled, updated }) {
    const [quantityChange, setQuantityChange] = useState(false);
    const [quantityInputVal, setQuantityInputVal] = useState(quantity);
    const selectedGlobalSelection = useSelector(state => state.selectedOptions.globalSelection, shallowEqual);
    const dispatch = useDispatch();

    useDebouncedEffect(() => {
        quantityChange && updateQuantity();
    }, [quantityInputVal], 500);

    useEffect(() => {
        setQuantityInputVal(quantity);
    }, [quantity]);

    function incrementQty() {
        if(quantityInputVal == maxQuantity) return;

        setQuantityChange(true);
        setQuantityInputVal(Number(quantityInputVal) + 1);
    }

    function decreaseQty() {
        setQuantityChange(true);
        setQuantityInputVal(quantityInputVal == 1 ? 1 : Number(quantityInputVal) - 1);
    }

    function inputOnChange(value) {
        setQuantityChange(true);

        if(value !== '') {
            setQuantityInputVal(Number(value > maxQuantity ? maxQuantity : value));
        } else {
            setQuantityInputVal(value);
        }
    }

    function updateQuantity() {
        const data = {
            product_id: productId,
            quantity: quantityInputVal,
            storage_id: selectionId,
            project_id: projectId
        };

        axios.patch(`${API_URL}/front/selection/product/update`, data)
            .then(res => {
                if (res.data.data.msg.length > 0) {
                    toast(<CustomToastMessage toastType="success" toastText={res.data.data.msg[0]} />, {autoClose: 10000});
                }

                if(selectionId == selectedGlobalSelection.id) dispatch(getCartSelection(projectId, selectionId));
                updated(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="product-quantity-input floating-label mb-0 position-relative">
            <input className={`w-100 text-ellipsis ${quantityInputVal !== '' ? 'has-value' : ''}`}
                   value={quantityInputVal}
                   onChange={e => inputOnChange(e.target.value)}
                   onInput={() => inputRestriction(/^[1-9]{1}\d*/)}
                   onBlur={() => {quantityInputVal == '' && setQuantityInputVal(quantity)}}
                   autoComplete="off"
                   disabled={disabled}
            />
            {!disabled &&
                <div className="product-quantity-input__buttons position-absolute">
                    <div className={`position-relative pointer ${quantityInputVal == maxQuantity ? 'disabled' : ''}`} onClick={incrementQty}></div>
                    <div className={`position-relative pointer ${quantityInputVal == 1 ? 'disabled' : ''}`} onClick={decreaseQty}></div>
                </div>
            }
        </div>
    );
}