import {useEffect, useState} from "react";
import  axios from "./axiosInstance";
import {API_URL} from "./constant";

export function makeSearchParamsObj(str) {
    // Decoding of original url parameters in case of russian letters and white spaces
    let decodedStr = decodeURIComponent(str.trim());

    // Removing first ? character if presence
    if(decodedStr.indexOf('?') === 0) decodedStr = decodedStr.substr(1);

    // Creating array with strings 'key=value'
    let tempArr = decodedStr.split('&');

    // Creating final object
    let searchObj = {};
    tempArr.forEach(item => {
        let innerArr = item.split('=');
        searchObj[innerArr[0]] = innerArr[1];
    });

    return searchObj;
}

export function makeSearchParamStr(obj) {
    // Creating array with strings 'key=value'
    let tempArr = [];

    Object.keys(obj).forEach(key => {
        if(obj[key] === undefined) return;

        tempArr.push(`${key}=${encodeURIComponent(obj[key])}`);
    });

    // Creating final string without leading ? character
    return tempArr.join('&');
}

export function setAxiosSettings(token, locale) {
    // Add authorization token and user locale for all further requests
    // console.log(`setAxiosSettings token: ${token}, locale: ${locale}`);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.Lang = locale;
}

// Clear all important keys from storages to handle "first" app visit after logout or 401 error
export function logoutClear() {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('managerId');
    sessionStorage.removeItem('canChangePrice');
    localStorage.removeItem('authToken');
    localStorage.removeItem('managerId');
    localStorage.removeItem('canChangePrice');
    localStorage.removeItem('selectedGlobalPartner');
    localStorage.removeItem('selectedGlobalProject');
    localStorage.removeItem('selectedGlobalSelection');
    localStorage.removeItem('userType');
    localStorage.removeItem('userDirection');
    localStorage.removeItem('isHorecaUser');
    localStorage.removeItem('authEmail');
    localStorage.removeItem('authPhone');
    delete axios.defaults.headers.common['Authorization'];
}

export function hiddenScroll(flag) {
    if(flag) {
        document.body.classList.add('hidden-scroll');
        document.getElementsByTagName( 'html' )[0].classList.add('hidden-scroll');
    } else {
        document.body.classList.remove('hidden-scroll');
        document.getElementsByTagName( 'html' )[0].classList.remove('hidden-scroll');
    }
}

// set selection data for generate PDF
export function setSelectionDataForPdf(projectId, storageId, selectionName, orderId) {
    localStorage.setItem('selectionDataForPdf', JSON.stringify({project_id: projectId, storage_id: storageId, selection_name: selectionName, order_id: orderId}));
}

// Form validation
export function setError(errorsObj, type, value) {
    let errorsObjCopy = Object.assign({}, errorsObj);

    errorsObjCopy[type] = value;
    return errorsObjCopy;
}

export function clearError(errorsObj, type) {
    let errorsObjCopy = Object.assign({}, errorsObj);

    if(errorsObj[type]) {
        delete errorsObjCopy[type];
    }

    return errorsObjCopy;
}

export function checkLength(errorsObj, type, val, minLength, maxLength) {
    const inputVal = val.trim();

    if(inputVal.length < minLength) {
        return setError(errorsObj, type, 'min');
    } else if(inputVal.length > maxLength) {
        return setError(errorsObj, type, 'max');
    } else {
        return errorsObj;
    }
}

export const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
        // check array of refs or one ref
        if(Array.isArray(ref)) {
            let isClickOutside = true;

            ref.map((item) => {
                isClickOutside = isClickOutside && item.current && !item.current.contains(e.target);
            })

            isClickOutside && callback();
        } else {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);

    }, [...(deps || []), delay]);
};

export function inputRestriction(regexp) {
    // eslint-disable-next-line
    const input = event.target;
    const currentVal = input.value;
    const checkedVal = currentVal.match(regexp);

    input.value = checkedVal ? checkedVal[0] : '';
}

export function checkPhone(errorsObj, phone) {
    const phoneVal = phone.trim();

    if(validateNumber(phoneVal) > 3 && validateNumber(phoneVal) != 12) {
        return setError(errorsObj,'phone', 'custom');
    } else {
        return errorsObj;
    }
}

export function validateNumber(number) {
    return number.replace(/\D+/g,"").length;
}

export function checkPasswordLength(errorsObj, type, val) {
    const inputVal = val.trim();

    if(inputVal.length < 6) {
        return setError(errorsObj, type, 'customMin');
    } else if(inputVal.length > 25) {
        return setError(errorsObj, type, 'customMax');
    } else {
        return errorsObj;
    }
}

export function checkEmail(errorsObj, email) {
    const emailVal = email.trim();

    if(emailVal.length > 0) {
        const pattern  = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!pattern.test(emailVal.toLowerCase())) {
            return setError(errorsObj,'email', 'custom');
        } else {
            return errorsObj;
        }
    } else {
        return errorsObj;
    }
}

export function useWindowSize() {
    // кастомный хук отслеживает разрешение окна и возвращает
    // значения:  desktop, tablet, mobile - для использования в компонентах
    // example
    // const windowSize = useWindowSize();
    const [size, setSize] = useState('');

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth;
            let windowSize = 'desktop';

            if (width < 768) {
                windowSize = 'mobile';
            } else if (width >= 768 && width < 1300) {
                windowSize = 'tablet';
            }

            setSize(windowSize);
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    return size;
}

export function getComparePdf(categoryId, displayType) {
    axios.get(`${API_URL}/front/compare/get-pdf?categoryid=${categoryId}&displaytype=${displayType ? 2 : 1}`, {
        responseType: 'blob'
    })
        .then(res => {
            //Create a Blob from the PDF Stream
            const file = new Blob([res.data], { type: res.headers['content-type'] });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `${res.headers['content-disposition'].split('=')[1]}`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => console.log(error));
}

export function isAuthorizedUser() {
    const authLocalStorageToken = localStorage.getItem('authToken');
    const authSessionStorageToken = sessionStorage.getItem('authToken');

    return (authLocalStorageToken || authSessionStorageToken) && authLocalStorageToken !== 'anonymous_horeca' && authLocalStorageToken !== 'anonymous_bt';
}

export function scrollTop(selector, offset = 0) {
    const elem = document.querySelector(selector);
    if (elem) {
        const topPos = elem.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: topPos, behavior: 'smooth' });
    }
}
