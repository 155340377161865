import React, {useEffect, useState} from "react";
import {hiddenScroll, useWindowSize, isAuthorizedUser} from "../../helper";
import {
    getCartSelection,
    getCustomers, getDirections,
    getSelectedCustomerProjects, updateCreateRenameModal,
    updateSelectedPartner,
    updateSelectedProject, updateSelectedSelection
} from "../../actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {FixedSizeList} from "react-window";
import $ from 'jquery';

export default function SelectSelectionModal({ isOpened, modalClosed }) {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('Выбор подборки и проекта')
    const [activeWindow, setActiveWindow] = useState('main')
    const [partnerInput, setPartnerInput] = useState('');
    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState({});
    const [searchPartnerInput, setSearchPartnerInput] = useState('');
    const [selectionInput, setSelectionInput] = useState('');
    const [currentProjects, setCurrentProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedSelection, setSelectedSelection] = useState({});
    const managerId = useSelector(state => state.getManagerId.managerId, shallowEqual);
    const customers = useSelector(state => state.customers.customers, shallowEqual);
    const selectedCustomerProjects = useSelector(state => state.selectedOptions.globalPartnerProjects, shallowEqual);
    const selectedGlobalPartner = useSelector(state => state.selectedOptions.globalPartner, shallowEqual);
    const selectedGlobalProject = useSelector(state => state.selectedOptions.globalProject, shallowEqual);
    const selectedGlobalSelection = useSelector(state => state.selectedOptions.globalSelection, shallowEqual);
    const windowSize = useWindowSize();
    const dispatch = useDispatch();
    const PartnerNameRow = ({ index, style }) => {
        const partner = partners[index];

        return (
            <div key={`partner-${index}`}
                onClick={() => {
                    setSelectedPartner({id: partner.id, name: partner.name})
                }}
                style={style}
            >
                <div className={`mobile-partners__item ${selectedPartner.id === partner.id ? 'active' : ''}`}>
                    {partner.name}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if(isAuthorizedUser()) {
            dispatch(getCustomers(managerId));
        }
    }, []);

    useEffect(() => {
        if(selectedGlobalPartner.name !== undefined) {
            setPartnerInput(selectedGlobalPartner.name);
            setSelectedPartner(selectedGlobalPartner);
        }
    }, [selectedGlobalPartner]);

    useEffect(() => {
        if(selectedGlobalProject.name !== undefined) {
            setSelectedProject(selectedGlobalProject);
        }
    }, [selectedGlobalProject]);

    useEffect(() => {
        if(selectedGlobalSelection.name !== undefined) {
            setSelectionInput(selectedGlobalSelection.name);
            setSelectedSelection(selectedGlobalSelection);
        } else {
            setSelectionInput('');
        }
    }, [selectedGlobalSelection]);

    useEffect(() => {
        setPartners(customers);
    }, [customers]);

    useEffect(() => {
        setCurrentProjects(selectedCustomerProjects);

        selectedCustomerProjects.map((project) => {
            if(project.project_id === selectedProject.id) {
                setSelectedProject({id: project.project_id, name: project.name, selections: project.selections})
            }
        })
    }, [selectedCustomerProjects]);

    useEffect(() => {
        hiddenScroll(isOpened ? isOpened : false);
        setOpenModal(isOpened ? isOpened : false);
    }, [isOpened])

    function closeActiveWindow() {
        if(activeWindow === 'main') {
            closeModal();
        } else if(activeWindow === 'partner' || activeWindow === 'project') {
            setActiveWindow('main');
            setModalTitle(t('choiceSelectionAndProject'));
        } else if(activeWindow === 'selection') {
            setActiveWindow('project');
            setModalTitle(t('choiceProject'));
        }
    }

    function closeModal() {
        setActiveWindow('main');
        modalClosed(true);
        setOpenModal(false);
    }

    function changeActiveWindow(window, windowTitle) {
        setActiveWindow(window);
        setModalTitle(windowTitle);
    }

    function partnersSearch(value) {
        const searchResult = customers.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

        setPartners(searchResult);
        setSearchPartnerInput(value);
    }

    function selectGlobalPartner(id, name) {
        dispatch(getSelectedCustomerProjects(id, managerId));
        dispatch(updateSelectedPartner({id: id, name: name}));
        dispatch(getDirections(id));

        setPartnerInput(name);
        setSelectedProject({});
        setSelectedSelection({});
        setSelectionInput('');

        changeActiveWindow('main', t('choiceSelectionAndProject'));
    }

    function selectGlobalProject(id, name, selections) {
        dispatch(updateSelectedProject({id: id, name: name, selections: selections}));

        changeActiveWindow('selection', t('choiceSelection'));
        setActiveWindow('selection');
    }

    function selectGlobalSelection(id, name) {
        dispatch(updateSelectedSelection({id: id, name: name}));
        dispatch(getCartSelection(selectedGlobalProject.id, id));

        setSelectionInput(name);
        changeActiveWindow('main', t('choiceSelectionAndProject'))
    }

    function resetPartner(e) {
        e.stopPropagation();

        setPartnerInput('');
        dispatch(updateSelectedPartner({}));
        dispatch(getDirections());
        dispatch(getCartSelection());
        setSelectedPartner({});
        setSelectedProject({});
        setSelectedSelection({});
        setSelectionInput('');
    }

    function resetSelection(e) {
        e.stopPropagation();
        setSelectionInput('');
        dispatch(updateSelectedProject({}));
        dispatch(updateSelectedSelection({}));
        dispatch(getCartSelection());
    }

    return (
        <div id="select-selection-modal" className={`select-selection position-fixed ${openModal ? 'open' : ''}`}>
            {windowSize === 'mobile' &&
                <>
                    <div className="select-selection__header d-flex justify-content-between align-items-center">
                        <h4 className="mb-0 semi-bold pointer" onClick={closeActiveWindow}>{modalTitle}</h4>
                        <svg className="svg-icon pointer" onClick={closeModal}>
                            <use xlinkHref="/images/sprite.svg#close"></use>
                        </svg>
                    </div>
                    <div className="select-selection__body mt-3 pt-1 position-relative">
                        <div className={`select-selection__body-main px-3 ${activeWindow === 'main' ? 'active' : ''}`}>
                            <div className="custom-dropdown dropdown mb-4">
                                <div className="custom-dropdown__btn floating-label position-relative m-0 pointer" onClick={() => changeActiveWindow('partner', t('partnersWord'))}>
                                    {partnerInput !== '' &&
                                        <svg className="reset-btn svg-icon position-absolute"
                                             onClick={resetPartner}
                                        >
                                            <use xlinkHref="/images/sprite.svg#close"></use>
                                        </svg>
                                    }
                                    <input id="partner-input-mobile"
                                           className={`w-100 pointer ${partnerInput !== '' ? 'has-value' : 'default-padding'}`}
                                           type="text"
                                           value={partnerInput}
                                           placeholder={t('choicePartner')}
                                           onChange={e => setPartnerInput(e.target.value)}
                                    />
                                    <label htmlFor="partner-input-mobile" className="text-gray-30 position-absolute mb-0">{t('partnerWord')}</label>
                                    <div className="custom-dropdown__arrow position-absolute"></div>
                                </div>
                            </div>
                            <div className={`custom-dropdown dropdown ${selectedPartner.id === undefined ? 'disabled' : ''}`}>
                                <div className="custom-dropdown__btn floating-label position-relative m-0 pointer"
                                     onClick={() => selectedPartner.id !== undefined && changeActiveWindow('project', t('choiceProject'))}
                                >
                                    {selectionInput !== '' &&
                                        <svg className="reset-btn svg-icon position-absolute"
                                             onClick={resetSelection}
                                        >
                                            <use xlinkHref="/images/sprite.svg#close"></use>
                                        </svg>
                                    }
                                    <input id="selection-input-mobile"
                                           className={`w-100 pointer ${selectionInput !== '' ? 'has-value' : 'default-padding'}`}
                                           type="text"
                                           value={selectionInput}
                                           placeholder={t('choiceProjectAndSelection')}
                                           onChange={e => setSelectionInput(e.target.value)}
                                           disabled={selectedPartner.id === undefined}
                                    />
                                    <label htmlFor="selection-input-mobile" className="text-gray-30 position-absolute mb-0">
                                        <span className="position-relative">{t('projectAndSelection')}</span>
                                    </label>
                                    <div className="custom-dropdown__arrow position-absolute"></div>
                                </div>
                            </div>
                        </div>
                        <div className={`select-selection__body-partner d-flex flex-column ${activeWindow === 'partner' ? 'active' : ''}`}>
                            <div className="px-3">
                                <div className="floating-label mb-4 position-relative">
                                    <input className={`w-100 ${searchPartnerInput !== '' ? 'has-value' : ''}`}
                                           type="text"
                                           value={searchPartnerInput}
                                           placeholder="Поиск партнера"
                                           onChange={(e) => partnersSearch(e.target.value)}
                                    />
                                    <svg className="svg-icon position-absolute">
                                        <use xlinkHref="/images/sprite.svg#search-icon"></use>
                                    </svg>
                                </div>
                            </div>
                            <FixedSizeList
                                className="mobile-partners custom-scroll flex-grow-1 mx-3 w-auto"
                                height={230} // height of the list
                                width="100%"
                                itemCount={partners.length}
                                itemSize={52} // height of one row
                            >
                                {PartnerNameRow}
                            </FixedSizeList>
                            <div className="px-3">
                                <div className={`w-100 text-center my-2 ${selectedPartner.id !== undefined ? 'btn btn-primary  ' : 'btn-outline btn-outline__disabled'}`}
                                     onClick={() => selectedPartner.id !== undefined && selectGlobalPartner(selectedPartner.id, selectedPartner.name)}
                                >
                                    {t('selectWord')}
                                </div>
                            </div>
                        </div>
                        <div className={`select-selection__body-project d-flex flex-column ${activeWindow === 'project' ? 'active' : ''}`}>
                            <div className="mobile-projects custom-scroll flex-grow-1 px-3">
                                {currentProjects.length > 0 && currentProjects.map((project, j) => {
                                    return (
                                        <div key={`project-${j}`}
                                             className={`mobile-projects__item text-ellipsis pointer ${selectedProject.id === project.project_id ? 'active' : ''}`}
                                             onClick={() => setSelectedProject({id: project.project_id, name: project.name, selections: project.selections})}
                                        >
                                            {project.name}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="mobile-projects-buttons px-3">
                                <div className="mobile-projects-buttons__add text-center pointer" onClick={() => {
                                    dispatch(updateCreateRenameModal({
                                        openModal: true,
                                        type: 'createProject',
                                        partnerId: selectedGlobalPartner.id,
                                    }));
                                    $('#create-modal').modal('show');
                                }}>
                                    {t('createNewProject')}
                                </div>
                                <div className={`w-100 text-center my-2 ${selectedProject.id !== undefined ? 'btn btn-primary  ' : 'btn-outline btn-outline__disabled'}`}
                                     onClick={() => selectedProject.id !== undefined && selectGlobalProject(selectedProject.id, selectedProject.name, selectedProject.selections)}
                                >
                                    {t('selectWord')}
                                </div>
                            </div>
                        </div>
                        <div className={`select-selection__body-selection d-flex flex-column ${activeWindow === 'selection' ? 'active' : ''}`}>
                            <div className="mobile-selections custom-scroll flex-grow-1 px-3">
                                {selectedProject.selections !== undefined && selectedProject.selections.map((selection, k) => {
                                    return (
                                        <div key={`selection-${k}`}
                                             className={`mobile-selections__item text-ellipsis pointer ${selectedSelection.id === selection.storage_id ? 'active' : ''}`}
                                             onClick={() => setSelectedSelection({id: selection.storage_id, name: selection.name})}
                                        >
                                            {selection.name}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="mobile-projects-buttons px-3">
                                <div className="mobile-projects-buttons__add text-center pointer" onClick={() => {
                                    dispatch(updateCreateRenameModal({
                                        openModal: true,
                                        type: 'createSelection',
                                        partnerId: selectedGlobalPartner.id,
                                        projectId: selectedGlobalProject.id
                                    }));
                                    $('#create-modal').modal('show');
                                }}>{t('createNewSelection')}</div>
                                <div className={`w-100 text-center my-2 ${selectedSelection.id !== undefined ? 'btn btn-primary  ' : 'btn-outline btn-outline__disabled'}`}
                                     onClick={() => selectedSelection.id !== undefined && selectGlobalSelection(selectedSelection.id, selectedSelection.name)}
                                >
                                    {t('selectWord')}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}