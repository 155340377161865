import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

export default function PublicRoute({ component: Component, ...rest }) {
    const [seoData, setSeoData] = useState({});

    useEffect(() => {
        rest.seoCallback(seoData);
    }, [seoData]);

    function setSeo(seoData) {
        setSeoData(seoData);
    }

    return (
        <Route {...rest} render={ props =>
            <Component {...props} seoCallback={setSeo} pageType={rest.pageType} isMarkdown={rest.isMarkdown}/>
        }
        />
    )
}