import React, {useEffect, useState} from "react";
import {inputRestriction} from "../../../../helper";
import {useTranslation} from "react-i18next";
import {getCartSelection} from "../../../../actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import CustomToastMessage from "../../../partials/CustomToastMessage";
import {API_URL} from "../../../../constant";
import axios from '../../../../axiosInstance';

export default function DiscountBonusInput({ type, storageId, projectId, productId, initValue, disabled, updated }) {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(initValue);
    const [error, setError] = useState(false);
    const selectedGlobalSelection = useSelector(state => state.selectedOptions.globalSelection, shallowEqual);
    const dispatch = useDispatch();
    const isPartner = localStorage.getItem('userType') == 2;

    useEffect(() => {
        setInputValue(initValue);
    }, [initValue])

    function updateInput() {
        const userType = isPartner ? 'customer' : 'manager';

        if(inputValue === '') {
            setInputValue(initValue);
            return;
        }

        if(inputValue != initValue) {
            const userTypeDiscount = userType === 'manager' ? 'discount' : 'buyer_discount';
            const data = {
                bulk: 0,
                product_id: productId,
                project_id: projectId,
                storage_id: storageId,
                field_name: type === 'bonus' ? 'retrobonus' : userTypeDiscount,
                field_value: inputValue
            }

            axios.patch(`${API_URL}/front/user/selections/${userType}/change_product_attributes`, data)
                .then(res => {
                    // if changed discount/bonus in globalSelectedSelection - update Cart
                    if(storageId == selectedGlobalSelection.id) dispatch(getCartSelection(projectId, storageId));
                    toast(<CustomToastMessage toastType="success" toastText={t('discountHasBeenChanged')} />, {autoClose: 5000});
                    updated(true);
                })
                .catch(function (error) {
                    console.log(error);
                    setError(true);
                });
        }
    }

    function inputOnChange(value) {
        if(value !== '' && type === 'discount') {
            setInputValue(Number(value > 100 ? 100 : value));
        } else {
            setInputValue(value);
        }
    }

    return (
        <div className={`product-discount-input floating-label mb-0 position-relative ${type === 'bonus' ? 'bonus-input' : ''} ${error ? 'has-error' : ''}`}>
            <input id={`discount-input-${productId}`}
                   className={`w-100 ${inputValue !== '' ? 'has-value' : ''}`}
                   type="number"
                   value={inputValue}
                   onChange={(e) => inputOnChange(e.target.value)}
                   onKeyDown={(e) => e.keyCode === 13 && updateInput()}
                   onFocus={() => setError(false)}
                   onBlur={updateInput}
                   disabled={disabled}
            />
            <label htmlFor={`discount-input-${productId}`} className="text-gray-30 position-absolute mb-0 text-nowrap">{type === 'bonus' ? t('bonusesWord') : `${t('discountWord')}, %`}</label>
        </div>
    );
}
