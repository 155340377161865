import React from "react";

export default function CustomToastMessage(props) {

    return (
        <div className={`custom-toast d-flex position-relative custom-toast--${props.toastType}`}>
            <div>
                <div className="custom-toast__text" dangerouslySetInnerHTML={{__html: props.toastText}}></div>
            </div>
        </div>
    )
}
