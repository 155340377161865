import React from "react";

function LoadingData() {
    return (
        <div className="loading-data-wrapper d-flex justify-content-center align-items-center position-relative">
            <div className="loading-data"></div>
        </div>
    )
}

export default LoadingData;