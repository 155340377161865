import React from "react";
import { Link } from "react-router-dom";

function SideMenuView(props) {
    return (
        <div className={`side-menu-view position-absolute ${props.viewSlug === props.currentView ? 'side-menu-view-opened' : ''}`}>
            <div className="side-menu-view__back d-flex align-items-center semi-bold position-relative pointer" onClick={() => props.setView('', props.viewLevel)}>{props.prevName}</div>
            <div className="side-menu-view__inner">
                {props.items.map(item => {
                    return (
                        <React.Fragment key={item.id}>
                            {item.children.length > 0 ?
                                <div className="side-menu-item position-relative pointer" onClick={() => props.setView(item.slug, props.viewLevel + 1)}>{item.name}</div>
                                :
                                <Link to={`${props.slugPart || ''}${item.slug}`} className="d-flex side-menu-item link-black no-decoration" onClick={props.closeMenu}>{item.name}</Link>
                            }
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    )
}

export default SideMenuView;