import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import ExchangeWidget from "./partials/ExchangeWidget";
import {shallowEqual, useSelector} from "react-redux";
import {isAuthorizedUser} from "../helper";

export default function Footer() {
    const { t } = useTranslation();
    const menuData = useSelector(state => state.getDirectionsData.menuData, shallowEqual);

    return (
        <footer id="main-footer" className="border-top">
            <div className="page-container mt-4 mb-3 mb-xl-4 py-xl-3">
                <div className="row">
                    <div className="col d-none d-md-block">
                        {menuData.map((item, i) => {
                            return (
                                <Fragment key={`link-${i}`}>
                                    <Link to={`/direction/${item.slug}`}
                                          className="link-dark no-decoration d-inline-block mb-3"
                                    >
                                        {item.name}
                                    </Link>
                                    <br/>
                                </Fragment>
                            )
                        })}
                        <Link to="/brands" className="link-dark no-decoration d-inline-block mb-3">{t('brandsWord')}</Link>
                    </div>
                    <div className="col d-flex flex-column text-center">
                        <a href="tel:0800404999" className="link-dark no-decoration d-inline-block flex-shrink-0 mb-2 mb-xl-3" style={{fontSize: '16px'}}>0 (800) 404–999</a>
                        <a href="https://goo.gl/maps/ie24SEfaPvxa9Zxh7" target="_blank" className="link-dark no-decoration mb-2 mb-xl-3 mx-xl-0" style={{fontSize: '16px'}}>{t('footerOffice')}</a>
                        <a href="mailto:contacts@mirs.com.ua" className="link-dark no-decoration mb-2 mb-xl-3">contacts@mirs.com.ua</a>
                    </div>
                    <div className="col d-none d-md-flex justify-content-end">
                        <div className="d-inline-flex flex-column align-items-start">
                            <Link to="/">
                                <img src="/images/logo-mirs.svg" alt="MIRS logo" className="header-logo float-md-right"/>
                            </Link>
                            {isAuthorizedUser() ? (
                                <div className="mt-2 pt-1">
                                    <ExchangeWidget/>
                                </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light text-center footer-rights">© {new Date().getFullYear()} {t('copyright')} </div>
        </footer>
    );
}
