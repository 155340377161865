import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import SideMenuView from "./SideMenuView";
import axios from "../axiosInstance";
import SelectSelectionModal from "./partials/SelectSelectionModal";
import {DEFAULT_LOCALE} from "../constant";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import LangDropdown from "./partials/LangDropdown";
import Loader from "./partials/Loader";
import ExchangeWidget from "./partials/ExchangeWidget";
import {isAuthorizedUser} from "../helper";
import DirectionDropdown from "./partials/DirectionDropdown";

class SideMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRequesting: false,
            showMenu: false,
            showSelectSelectionModal: false,
            menuData: {},
            currentDirection: '',
            currentViewLevel0: '',
            currentViewLevel1: '',
            currentViewLevel2: '',
            localeName: localStorage.getItem('userLocale') || DEFAULT_LOCALE
        };

        this.getSideMenuData = this.getSideMenuData.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setView = this.setView.bind(this);
    }

    componentDidUpdate(prevProps) {
        // clear Menu if languageChanged
        if (this.props.t !== prevProps.t) {
            this.setState({menuData: {}, currentDirection: ''});
        }
    }

    getSideMenuData(directionSlug) {
        const customerId = this.props.globalPartner.id !== undefined ? `?customer_id=${this.props.globalPartner.id}` : '';

        if(this.state.currentDirection !== directionSlug && !this.state.menuData[directionSlug]) {
            this.setState({isRequesting: true});

            axios.get(`${API_URL}/front/direction/${directionSlug}${customerId}`)
                .then(res => {
                    const menuData = Object.assign({}, this.state.menuData, {[directionSlug]: res.data.children});

                    this.setState({menuData, currentDirection: directionSlug, isRequesting: false});
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({isRequesting: false});
                });
        } else {
            this.setState({currentDirection: directionSlug});
        }
    }

    closeMenu() {
        this.setState({showMenu: false, currentViewLevel0: '', currentViewLevel1: '', currentViewLevel2: ''});
    }

    setView(newVal, level) {
        switch(level) {
            case 0:
                this.setState({currentViewLevel0: newVal});
                break;
            case 1:
                this.setState({currentViewLevel1: newVal});
                break;
            case 2:
                this.setState({currentViewLevel2: newVal});
                break;
            default:
                this.setState({currentViewLevel0: '', currentViewLevel1: '', currentViewLevel2: ''});
        }
    }

    render() {
        const { t, sideMenuData } = this.props;

        return (
            <React.Fragment>
                <div id="main-burger" className="mr-3 pl-0 pointer" onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                    <svg className="svg-icon">
                        <use xlinkHref="/images/sprite.svg#burger-menu"></use>
                    </svg>
                </div>
                <div id="side-menu-bg" className={`position-fixed d-md-none ${this.state.showMenu ? 'side-menu-bg-active' : ''}`} onClick={this.closeMenu}></div>
                <div id="side-menu" className={`position-fixed d-md-none ${this.state.showMenu ? 'side-menu-opened' : ''}`}>
                    <div id="side-menu-header" className="d-flex align-items-center position-absolute w-100 px-2 d-flex">
                        <div className="p-3" onClick={this.closeMenu}>
                            <svg className="svg-icon">
                                <use xlinkHref="/images/sprite.svg#close"></use>
                            </svg>
                        </div>
                        <Link to="/" className="p-2">
                            <img src="/images/logo-mirs.svg" alt="MIRS logo" className="header-logo"/>
                        </Link>
                    </div>
                    <div id="side-menu-main-view" className="h-100 d-flex align-items-start flex-column flex-wrap">
                        <div className="position-relative w-100 mb-auto">
                            {this.state.isRequesting && <Loader/>}
                            {!isAuthorizedUser() && (
                                <>
                                    <div className="d-flex justify-content-between align-items-center ml-3 mr-2 mb-2">
                                        {t('directionTitle')}
                                        <DirectionDropdown isDirectionUpdated={(flag) => flag && this.closeMenu()}/>
                                    </div>
                                    <hr className="mt-1 mb-1"/>
                                </>
                            )}
                            <div className="d-flex justify-content-between align-items-center ml-3 mr-2">
                                {t('systemLanguage')}
                                <LangDropdown thisLocale={this.state.localeName}
                                              isLangUpdated={(flag) => flag && this.closeMenu()}/>
                            </div>
                            <hr className={`mt-1 mb-0`}/>
                            {isAuthorizedUser() && (
                                <>
                                    <ExchangeWidget/>
                                    <hr className="mt-1 mb-0"/>
                                    <Link to="/cabinet/personal-data"
                                          onClick={this.closeMenu}
                                          className="d-flex side-menu-item link-black no-decoration"
                                    >
                                        <div className="position-relative pointer d-flex align-items-center">
                                            <svg className="svg-icon mr-3">
                                                <use xlinkHref="/images/sprite.svg#my-data-icon"></use>
                                            </svg>
                                            <span>{t('myCabinetWord')}</span>
                                        </div>
                                    </Link>
                                    <hr className="mt-0 mb-3"/>
                                    <div
                                        className="select-selection-btn side-menu-item position-relative pointer semi-bold mb-3"
                                        onClick={() => this.setState({showSelectSelectionModal: !this.state.showSelectSelectionModal})}
                                    >
                                        {t('choiceSelectionAndProject')}
                                    </div>
                                    <SelectSelectionModal isOpened={this.state.showSelectSelectionModal}
                                                          modalClosed={(flag) => this.setState({showSelectSelectionModal: !flag})}
                                    />
                                    <hr className="mt-0 mb-3"/>
                                </>
                            )}
                            {sideMenuData.length > 0 && sideMenuData.map((menuItem, index) => {
                                return (
                                    <Link key={`side-menu-item-${index}`}
                                          to={`/direction/${menuItem.slug}`}
                                          className="side-menu-item side-menu-item__direction-link d-block position-relative no-decoration"
                                          onClick={this.closeMenu}
                                    >
                                        {menuItem.name}
                                    </Link>
                                )
                            })}
                            <Link to="/brands"
                                  className="d-flex side-menu-item link-black no-decoration"
                                  onClick={this.closeMenu}
                            >{t('brandsWord')}</Link>
                            {this.props.hasActions &&
                                <Link to="/actions"
                                      className="d-flex side-menu-item link-black no-decoration"
                                      onClick={this.closeMenu}
                                >{t('actionsWord')}</Link>
                            }
                        </div>
                        <div id="side-menu-contacts" className="py-5 px-4">
                            <p className="semi-bold">{t('contactsWord')}</p>
                            <a href="tel:0800404999" className="d-flex link-black no-decoration d-inline-block" style={{fontSize: '13px'}}>0 (800) 404–999</a>
                            <a href="https://goo.gl/maps/ie24SEfaPvxa9Zxh7" target="_blank" className="d-flex link-black no-decoration d-inline-block" style={{fontSize: '13px'}}>{t('footerOffice')}</a>
                            <a href="mailto:contacts@mirs.com.ua" className="d-flex link-black no-decoration d-inline-block">contacts@mirs.com.ua</a>
                        </div>
                    </div>
                    {Object.keys(this.state.menuData).length > 0 && sideMenuData.map((menuItem, index) => {
                        return (
                            <SideMenuView key={`side-menu-view-${index}`}
                                          prevName={menuItem.name}
                                          viewSlug={menuItem.slug}
                                          currentView={this.state.currentViewLevel0}
                                          viewLevel={0}
                                          items={this.state.menuData[this.state.currentDirection]}
                                          setView={this.setView}
                                          closeMenu={this.closeMenu}
                            />
                        )
                    })}
                    {this.state.currentDirection !== '' && this.state.menuData[this.state.currentDirection].map(sectionItem => {
                        const markdownSlug = this.state.currentDirection === 'markdown' ? 'markdown/' : '';

                        return (
                            sectionItem.children.length > 0 &&
                                <React.Fragment key={sectionItem.id}>
                                    <SideMenuView slugPart={`/catalog/${markdownSlug}`}
                                                  prevName={sectionItem.name}
                                                  viewSlug={sectionItem.slug}
                                                  currentView={this.state.currentViewLevel1}
                                                  viewLevel={1}
                                                  items={sectionItem.children}
                                                  setView={this.setView}
                                                  closeMenu={this.closeMenu}
                                    />
                                    {sectionItem.children.map(categoryItem => {
                                        return (
                                            categoryItem.children.length > 0 &&
                                                <SideMenuView key={categoryItem.id}
                                                              slugPart={`/catalog/${markdownSlug}${categoryItem.slug}?subcategory=`}
                                                              prevName={categoryItem.name}
                                                              viewSlug={categoryItem.slug}
                                                              currentView={this.state.currentViewLevel2}
                                                              viewLevel={2}
                                                              items={categoryItem.children}
                                                              setView={this.setView}
                                                              closeMenu={this.closeMenu}
                                                />
                                        );
                                    })}
                                </React.Fragment>
                        );
                    })}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        globalPartner: state.selectedOptions.globalPartner,
        hasActions: state.hasActions.hasActions,
    }
};

export default withTranslation()(connect(mapStateToProps)(SideMenu));