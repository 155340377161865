import React, { Component, Fragment } from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import i18n from "i18next";
import axios from '../axiosInstance';
import RouteChangeListener from "./RouteChangeListener";
import SeoBlock from "./SeoBlock";
import AuthRoute from "./partials/AuthRoute"; // Специфический роут для страниц логина и работы с паролем
import PrivateRoute from "./partials/PrivateRoute"; // Отсюда без авторизации отправит на логин
import PublicRoute from "./partials/PublicRoute"; // Сюда можно переходить без авторизации
import Loader from "./partials/Loader";
import {setAxiosSettings, isAuthorizedUser} from "../helper";

import { withTranslation } from "react-i18next";
import PublicProduct from "./PublicProduct";
import FootersWrapBlock from "./partials/FootersWrapBlock";
import HeadersWrapBlock from "./partials/HeadersWrapBlock"; // Компонент на который можно перейти по прямой ссылке не авторизованному пользователю. Устанавливает что нужно и отправляет на БКТ
const Direction = React.lazy(() => import('./Direction'));
const Section = React.lazy(() => import('./Section'));
const CatalogPage = React.lazy(() => import('./pages/catalog/CatalogPage'));
const MarkdownCatalogPage = React.lazy(() => import('./pages/catalog/MarkdownCatalogPage'));
const SearchCatalogPage = React.lazy(() => import('./pages/catalog/SearchCatalogPage'));
const FavoritesCatalogPage = React.lazy(() => import('./pages/cabinet/FavoritesCatalogPage'));
const Catalog = React.lazy(() => import('./Catalog'));
const ActionsPage = React.lazy(() => import('./pages/actions/ActionsPage'));
const SingleActionPage = React.lazy(() => import('./pages/actions/SingleActionPage'));
const Brands = React.lazy(() => import('./Brands'));
const BrandPage = React.lazy(() => import('./BrandPage'));
const NotFound = React.lazy(() => import('./NotFound'));
const Welcome = React.lazy(() => import('./Welcome'));
const ProductPageContainer = React.lazy(() => import('../containers/ProductPageContainer'));
const ComparisonListsContainer = React.lazy(() => import('../containers/ComparisonListsContainer'));
const CompareContainer = React.lazy(() => import('../containers/CompareContainer'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const LoginTokenPage = React.lazy(() => import('./pages/LoginTokenPage'));
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage'));
const NewPasswordPage = React.lazy(() => import('./pages/NewPasswordPage'));
const PersonalDataCabinetPage = React.lazy(() => import('./pages/cabinet/PersonalDataCabinetPage'));
const ManagerDataCabinetPage = React.lazy(() => import('./pages/cabinet/ManagerDataCabinetPage'));
const CompanyDataCabinetPage = React.lazy(() => import('./pages/cabinet/CompanyDataCabinetPage'));
const OrdersCabinetPage = React.lazy(() => import('./pages/cabinet/OrdersCabinetPage'));
const SelectionsCabinetPage = React.lazy(() => import('./pages/cabinet/SelectionsCabinetPage'));
const OneSelectionCabinetPage = React.lazy(() => import('./pages/cabinet/OneSelectionCabinetPage'));
const SearchSelectionPage = React.lazy(() => import('./pages/cabinet/SearchSelectionPage'));

/*
* Для работы с НЕ АВТОРИЗОВАНЫМ пользователем магия реализована в компонентах PublicHeader, Direction и PublicProduct
* Работает для слагов  direction = [ bytovaya_tehnika | posuda ] и никак иначе - много костылей, но работает.
* Храним выбранный direction в redux publicUsedDirection и в localStorage userDirection
* используем 2 закостыленных Bearer токена для анонимного пользователя: anonymous_bt и anonymous_horeca
* При переходе на страницу /direction/{slug}, если пользователь не авторизован, устанавливаем новые значения в localStorage и redux
* при переходе на /{directionSlug}/product/{slug} в PublicProduct устанавливаем значения в localStorage и redux на основании directionSlug и переводимся на стандартную БКТ
* */

// We need to clear sessionStorage at App start if user uncheck remember me for proper behaviour (page reload/duplication, open in new tab)
sessionStorage.removeItem('authToken');

const token = localStorage.getItem('authToken');
const locale = localStorage.getItem('userLocale') || 'ua';

if(token && isAuthorizedUser()) {
    // console.log(`token && isAuthorizedUser()`);
    // Global axios settings for further requests for Authorized user!
    setAxiosSettings(token, locale)
} else if(token) {
    //Для не авторизованного пользователя, если есть токен в localStorage, устанавливаем настройки по умолчанию на этот токен
    // console.log(`token && !isAuthorizedUser()`);
    setAxiosSettings(token, locale)
} else {
    // Для не авторизованного пользователя устанавливаем настройки по умолчанию на Horeca
    setAxiosSettings('anonymous_horeca', locale)
}

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seoData: {}
        };

        this.setSeo = this.setSeo.bind(this);
        this.setAxiosLangSettings = this.setAxiosLangSettings.bind(this);
    }

    componentDidMount() {
        this.props.i18n.on('languageChanged', this.setAxiosLangSettings);
    }

    componentWillUnmount() {
        this.props.i18n.off('languageChanged', this.setAxiosLangSettings);
    }

    setSeo(seoData) {
        this.setState({seoData});
    }

    setAxiosLangSettings() {
        axios.interceptors.request.use(function (config) {
            config.headers.Lang =  i18n.language;

            return config;
        });
    }

    render() {
        return (
            <Fragment>
                <SeoBlock seo={this.state.seoData} />
                <React.Suspense fallback={<Loader/>}>
                    <Router>
                        <RouteChangeListener>
                            <HeadersWrapBlock/>
                            <main className="flex-grow-1">
                                <Switch>
                                    <PublicRoute path="/login/token" component={LoginTokenPage} seoCallback={this.setSeo}/>
                                    <AuthRoute path="/login" component={LoginPage} seoCallback={this.setSeo} />
                                    <AuthRoute path="/reset-password" component={ResetPasswordPage} seoCallback={this.setSeo} />
                                    <AuthRoute path="/new-password" component={NewPasswordPage} seoCallback={this.setSeo} />
                                    <PublicRoute exact path="/" component={Welcome} seoCallback={this.setSeo}/>
                                    <PublicRoute path="/:directionSlug/product/:slug" component={PublicProduct} seoCallback={this.setSeo}/>
                                    <PublicRoute path="/direction/:slug" component={Direction} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/section/markdown/:slug" component={Section} isMarkdown={true} seoCallback={this.setSeo}/>
                                    <PublicRoute path="/section/:slug" component={Section} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/catalog/markdown/:slug" component={MarkdownCatalogPage} pageType="catalog" isMarkdown={true} seoCallback={this.setSeo}/>
                                    {/*<PublicRoute path="/catalog/:slug" component={Catalog} pageType="catalog" seoCallback={this.setSeo}/>*/}
                                    <PublicRoute path="/catalog/:slug" component={CatalogPage} pageType="catalog" seoCallback={this.setSeo}/>
                                    <PublicRoute path="/search" component={SearchCatalogPage} pageType="search" seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/product/markdown/:slug" component={ProductPageContainer} isMarkdown={true} seoCallback={this.setSeo}/>
                                    <PublicRoute path="/product/:slug" component={ProductPageContainer} seoCallback={this.setSeo}/>
                                    <PrivateRoute exact path="/comparison-lists" component={ComparisonListsContainer} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/compare/:slug" component={CompareContainer} seoCallback={this.setSeo}/>
                                    <PublicRoute exact path="/actions" component={ActionsPage} seoCallback={this.setSeo}/>
                                    <PublicRoute exact path="/action/:slug" component={SingleActionPage} seoCallback={this.setSeo}/>
                                    <PublicRoute exact path="/brands" component={Brands} seoCallback={this.setSeo}/>
                                    <PublicRoute path="/brand/:slug" component={BrandPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/personal-data" component={PersonalDataCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/manager-data" component={ManagerDataCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/company-data" component={CompanyDataCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/orders" component={OrdersCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/selections" component={SelectionsCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/selection" component={OneSelectionCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/selections-search" component={SearchSelectionPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/favorites" component={FavoritesCatalogPage} pageType="favorites" seoCallback={this.setSeo}/>
                                    <Route path="/404" render={routeProps => <NotFound {...routeProps} seoCallback={this.setSeo} />}></Route>
                                    <Redirect to="/404" />
                                </Switch>
                            </main>
                            <FootersWrapBlock/>
                        </RouteChangeListener>
                    </Router>
                </React.Suspense>
            </Fragment>
        );
    }
}

export default withTranslation()(App);