export const managerId = localStorage.getItem('managerId');
export const storagePartner = localStorage.getItem('selectedGlobalPartner');
export const storagePartnerId = storagePartner ? `customer_id=${JSON.parse(storagePartner).id}` : '';
export const storageProject = localStorage.getItem('selectedGlobalProject');
export const storageSelection = localStorage.getItem('selectedGlobalSelection');
export const DEFAULT_LOCALE = 'ua';
export const DEFAULT_PUBLIC_DIRECTION = 'HORECA';
export const API_URL=process.env.REACT_APP_API_URL;
export const API_URL_IMAGE=process.env.REACT_APP_API_URL_IMAGE;
export const IS_PRODUCTION_ENVIRONMENT = process.env.REACT_APP_APP_ENV === 'production';
export const APP_ENVIRONMENT = process.env.REACT_APP_APP_ENV;
export const CUSTOMER_USER_TYPE = '2';
export const MANAGER_USER_TYPE = '1';
