import React, {Component} from "react";
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next";
import axios from "../axiosInstance";
import {getSelectedCustomerProjects, updateSelectionsPage} from "../actions";
import LoaderBtn from "./partials/LoaderBtn";
import {checkLength, clearError} from "../helper";
import {connect} from "react-redux";
import $ from 'jquery';
import {API_URL} from "../constant";


class SettingsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orientation: 'portrait',
            coverText: '',
            authorInput: '',
            phoneInput: '',
            addressInput: '',
            productsSpec: true,
            productsOverview: false,
            productsComparison: false,
            columnQuantity: {checked: true, disabled: true},
            columnPrice: {checked: true, disabled: true},
            columnSum: {checked: true, disabled: true},
            columnDiscount: {checked: true, disabled: true},
            columnPriceWithDiscount: {checked: true, disabled: true},
            columnSumWithDiscount: {checked: true, disabled: true},
            columnBonus: {checked: true, disabled: false},
            pdfTitlePage: true,
            notesText: '',
            pdfLogo: 'mirs',
            currentColumnsPreset: 'allColumns',
            columnView: true,
            isRequest: false,
            isPartner: false
        };

        this.changeTemplateOrientation = this.changeTemplateOrientation.bind(this);
        this.changeInputValue = this.changeInputValue.bind(this);
        this.PDFModuleSelect = this.PDFModuleSelect.bind(this);
        this.PDFTitleLogo = this.PDFTitleLogo.bind(this);
        this.PDFTitlePage = this.PDFTitlePage.bind(this);
        this.changeColumnsPreset = this.changeColumnsPreset.bind(this);
        this.PDFSpecColumns = this.PDFSpecColumns.bind(this);
        this.changeAddressText = this.changeAddressText.bind(this);
        this.changeNotesText = this.changeNotesText.bind(this);
        this.generatePDF = this.generatePDF.bind(this);
    }

    componentDidMount() {
        const settingsModalData = JSON.parse(localStorage.getItem('settingsModal'));

        if(settingsModalData) this.setState(settingsModalData);

        // some changes if user are partner
        if(+localStorage.getItem('userType') === 2) {
            this.setState({columnBonus: {checked: false, disabled: false}});
            this.setState({isPartner: true})
        } else {
            this.setState({isPartner: false})
        }

        // Workaround to save default settings modal state if localStorage is empty (in other case it will be redirect to 404)
        $('#settings-modal').on('show.bs.modal', () => {
            if(!JSON.parse(localStorage.getItem('settingsModal'))) this.saveChanges();
        });
    }

    changeTemplateOrientation(e) {
        const elem = e.currentTarget;

        if(elem.classList.contains('active')) return;

        this.setState({orientation: elem.dataset.orientation}, this.saveChanges);
    }

    changeInputValue(e, inputName) {
        const inputVal = e.target.value;

        if(inputVal.length > 255) return;

        this.setState({[inputName]: inputVal}, this.saveChanges);
    }

    PDFModuleSelect(e) {
        const elem = e.target;

        this.setState({[elem.value]: elem.checked}, this.saveChanges);
    }

    PDFSpecColumns(e) {
        const elem = e.target;

        this.setState({[elem.value]: {checked: elem.checked, disabled: elem.disabled}}, this.saveChanges);
    }

    PDFTitleLogo(e) {
        this.setState({pdfLogo: e.target.value}, this.saveChanges);
    }

    PDFTitlePage(e) {
        this.setState({pdfTitlePage: e.target.checked}, this.saveChanges);
    }

    changeColumnsPreset(name) {
        let checked = [];
        let disabled = [];
        
        if(name === 'allColumns') {
            checked = [true, true, true, true, true, true, true];
            disabled = [true, true, true, true, true, true, false];
        } else if(name === 'roznicaColumns' || name === 'forBuyerWithoutDiscount') {
            checked = [true, true, true, false, false, false, false];
            disabled = [true, true, true, true, true, true, true];
        } else if(name === 'forCustomerWithDiscount') {
            checked = [true, true, true, true, true, true, false];
            disabled = [true, true, true, true, true, true, true];
        } else {
            checked = [false, false, false, false, false, false, false];
            disabled = [false, false, false, false, false, false, false];
        }

        if(this.state.isPartner) {
            this.setState({columnView: name === 'forBuyerWithoutDiscount' || name === 'forCustomerWithDiscount' ? false : true})

            if(name === 'allColumns') {
                checked = [true, true, true, true, true, true, false];
            }
        }

        this.setState({currentColumnsPreset: name}, this.saveChanges);
        this.setState({columnQuantity: {checked: checked[0], disabled: disabled[0]}}, this.saveChanges);
        this.setState({columnPrice: {checked: checked[1], disabled: disabled[1]}}, this.saveChanges);
        this.setState({columnSum: {checked: checked[2], disabled: disabled[2]}}, this.saveChanges);
        this.setState({columnDiscount: {checked: checked[3], disabled: disabled[3]}}, this.saveChanges);
        this.setState({columnPriceWithDiscount: {checked: checked[4], disabled: disabled[4]}}, this.saveChanges);
        this.setState({columnSumWithDiscount: {checked: checked[5], disabled: disabled[5]}}, this.saveChanges);
        this.setState({columnBonus: {checked: checked[6], disabled: disabled[6]}}, this.saveChanges);
    }

    changeAddressText(e) {
        const textareaVal = e.target.value;

        if(textareaVal.length > 1000) return;

        this.setState({addressInput: textareaVal}, this.saveChanges);
    }

    changeNotesText(e) {
        const textareaVal = e.target.value;

        if(textareaVal.length > 800) return;

        this.setState({notesText: textareaVal}, this.saveChanges);
    }

    saveChanges() {
        localStorage.setItem('settingsModal', JSON.stringify(this.state));
    }

    generatePDF() {
        let productSpecColumnsArr =[];

        this.state.columnQuantity.checked && productSpecColumnsArr.push('quantity');
        this.state.columnPrice.checked && productSpecColumnsArr.push('price');
        this.state.columnSum.checked && productSpecColumnsArr.push('sum');
        this.state.columnDiscount.checked && productSpecColumnsArr.push('discount');
        this.state.columnPriceWithDiscount.checked && productSpecColumnsArr.push('priceWithDesc');
        this.state.columnSumWithDiscount.checked && productSpecColumnsArr.push('sumWithDesc');
        this.state.columnBonus.checked && productSpecColumnsArr.push('bonus');

        let data = {
            project_id: JSON.parse(localStorage.getItem('selectionDataForPdf')).project_id,
            storage_id: JSON.parse(localStorage.getItem('selectionDataForPdf')).storage_id,
            order_id: JSON.parse(localStorage.getItem('selectionDataForPdf')).order_id,
            orientation: this.state.orientation,
            coverText: this.state.coverText,
            productsSpec: this.state.productsSpec,
            productsOverview: this.state.productsOverview,
            productsComparison: this.state.productsComparison,
            productSpecColumns: productSpecColumnsArr,
            notesText: this.state.notesText.replace(/\r?\n/g, '<br />'),
        }

        if(this.state.pdfLogo !== '') data.pdfLogo = this.state.pdfLogo;

        if(this.state.isPartner) {
            data.customer_id = JSON.parse(localStorage.getItem('selectedGlobalPartner')).id;
            data.ownerName = this.state.authorInput;
            data.ownerPhone = this.state.phoneInput;
            data.ownerAddress = this.state.addressInput;
            data.hasCoverPage = this.state.pdfTitlePage ? 1 : 0;
        }

        if (this.state.currentColumnsPreset === 'forCustomerWithDiscount') data.buyerSpecialPrice = true;

        this.setState({isRequest: true});

        axios.post(`${API_URL}/front/selection/get-data-pdf`, data, {
            responseType: 'blob'
        })
            .then(res => {
                this.setState({isRequest: false});

                //Create a Blob from the PDF Stream
                const file = new Blob([res.data], { type: res.headers['content-type'] });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', `${res.headers['content-disposition'].split('=')[1]}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { t, currency } = this.props;
        const roznicalColumns = this.state.isPartner ? 'forBuyerWithoutDiscount' : 'roznicaColumns';
        const columnsViewData = [
            'allColumns',
            roznicalColumns,
            'freeColumns'
        ];
        let currencyToRender = '';

        if (this.state.isPartner) columnsViewData.push('forCustomerWithDiscount');
        if (currency) currencyToRender = currency.toLowerCase();

        return (
            <div id="settings-modal" className="modal custom-scroll" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content custom-scroll">
                        <div className="modal-body d-flex">
                            <div className="d-flex flex-column w-100">
                                <div id="settings-modal-header" className="position-relative">
                                    <button type="button"
                                            className="btn bg-transparent position-absolute"
                                            // data-toggle="modal"  use it if need open cart after close this modal
                                            // data-target="#cart-selection-modal"
                                            data-dismiss="modal"
                                    />
                                    <h2 className="text-center text-xl-left mb-0 text-black">{t('pdfSettings')}</h2>
                                    <button id="settings-modal-close" type="button" className="d-none" data-toggle="modal" data-target="#cart-selection-modal" data-dismiss="modal"></button>
                                </div>
                                <div id="settings-modal-wrapper" className="d-flex flex-column flex-grow-1">
                                    <div className="flex-grow-1">
                                        <div className="mb-4">
                                            <div className="settings-section-name semi-bold mb-2">{t('orientationWord')}</div>
                                            <div className="d-flex flex-column flex-md-row">
                                                <div className={`settings-orientation-item d-flex align-items-center pointer mr-4 pr-2 mb-3 mb-md-0 ${this.state.orientation === 'portrait' ? 'active' : ''}`} data-orientation="portrait" onClick={this.changeTemplateOrientation}>
                                                    <svg className="svg-icon mr-2">
                                                        <use xlinkHref="/images/sprite.svg#v-orientation"></use>
                                                    </svg>
                                                    <span>{t('verticalWord')}</span>
                                                </div>
                                                <div className={`settings-orientation-item d-flex align-items-center pointer ${this.state.orientation === 'landscape' ? 'active' : ''}`} data-orientation="landscape" onClick={this.changeTemplateOrientation}>
                                                    <svg className="svg-icon mr-2">
                                                        <use xlinkHref="/images/sprite.svg#h-orientation"></use>
                                                    </svg>
                                                    <span>{t('horizontalWord')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.isPartner ?
                                            <>
                                                <div className="floating-label mb-4 position-relative">
                                                    <input id="cover-author"
                                                           className={`w-100 has-value`}
                                                           type="text"
                                                           value={this.state.authorInput}
                                                           onChange={(e) => this.changeInputValue(e, 'authorInput')}
                                                    />
                                                    <label htmlFor="cover-author" className="text-gray-30 position-absolute mb-0">
                                                        {t('buyerWord')}
                                                    </label>
                                                </div>
                                                <div className="floating-label mb-4 position-relative">
                                                    <input id="cover-phone"
                                                           className={`w-100 has-value`}
                                                           type="text"
                                                           value={this.state.phoneInput}
                                                           onChange={(e) => this.changeInputValue(e, 'phoneInput')}
                                                    />
                                                    <label htmlFor="cover-phone" className="text-gray-30 position-absolute mb-0">
                                                        {t('phoneWord')}
                                                    </label>
                                                </div>
                                                <div className="floating-label position-relative mb-4 pb-2">
                                                    <textarea id="address-text"
                                                              rows="3"
                                                              className="w-100 has-value"
                                                              value={this.state.addressInput}
                                                              onChange={this.changeAddressText}
                                                              style={{resize: 'none'}}
                                                    />
                                                    <label htmlFor="address-text" className="text-gray-30 position-absolute mb-0">
                                                        {t('buyerAddress')}
                                                    </label>
                                                </div>
                                            </>
                                            :
                                            <div className="mb-3">
                                                <div className="settings-subsection-name text-dark-gray mb-1">{t('coverTitle')}</div>
                                                <input type="text"
                                                       className="w-100"
                                                       value={this.state.coverText}
                                                       onChange={(e) => this.changeInputValue(e, 'coverText')}
                                                />
                                            </div>
                                        }
                                        <div className="d-flex flex-column flex-md-row mb-3">
                                            <div className="w-50">
                                                <div className="modal-checkbox mr-4 pr-2 mb-3 mb-xl-2 pb-1 pb-md-0 pb-xl-1">
                                                    <input id="productsSpec" type="checkbox" name="pdf-modules" value="productsSpec" checked={this.state.productsSpec} onChange={this.PDFModuleSelect} />
                                                    <label htmlFor="productsSpec">{t('specificationWord')}</label>
                                                </div>
                                                <div className="modal-checkbox mr-4 pr-2 mb-3 mb-md-0 mb-xl-2 pb-1 pb-md-0 pb-xl-1">
                                                    <input id="productsOverview" type="checkbox" name="pdf-modules" value="productsOverview" checked={this.state.productsOverview} onChange={this.PDFModuleSelect} />
                                                    <label htmlFor="productsOverview">{t('productOverview')}</label>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="modal-checkbox">
                                                    <input id="productsComparison" type="checkbox" name="pdf-modules" value="productsComparison" checked={this.state.productsComparison} onChange={this.PDFModuleSelect} />
                                                    <label htmlFor="productsComparison">{t('compareProducts')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="custom-dropdown dropdown mb-2">
                                                <div className="custom-dropdown__btn floating-label position-relative m-0 pointer" data-toggle="dropdown">
                                                    <input className="w-100 pointer text-ellipsis has-value"
                                                           type="text"
                                                           value={t(this.state.currentColumnsPreset)}
                                                           autoComplete="off"
                                                           onChange={e => this.changeColumnsPreset(e.target.value)}
                                                           readOnly
                                                    />
                                                    <label className="text-gray-30 position-absolute mb-0">
                                                        {t('columnsViewDropdown')}
                                                    </label>
                                                    <div className="custom-dropdown__arrow position-absolute"></div>
                                                </div>
                                                <div className="custom-dropdown__list custom-scroll dropdown-menu w-100">
                                                    {columnsViewData.map((preset, i) => {
                                                        const isSelectedPresetName = this.state.currentColumnsPreset == preset;

                                                        if(this.state.isPartner && preset === 'freeColumns') return;

                                                        return (
                                                            <div key={`custom-item-${i}`}
                                                                 className={`custom-dropdown__item pointer text-ellipsis ${isSelectedPresetName ? 'selected-filter' : ''}`}
                                                                 onClick={() => !isSelectedPresetName && this.changeColumnsPreset(preset)}
                                                            >
                                                                <span className={!isSelectedPresetName ? 'semi-bold' : ''}>{t(preset)}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className={`settings-subsection-name text-dark-gray mb-2 ${this.state.isPartner && !this.state.columnView ? 'd-none' : ''}`}>{t('columnsView')}</div>
                                            <div className={`${this.state.isPartner ? 'column-wrap' : ''} justify-content-between ${this.state.isPartner && !this.state.columnView ? 'd-none' : 'd-flex'}`}>
                                                <div className={`flex-wrap w-50 ${this.state.isPartner ? 'd-none' : 'd-md-flex d-xl-block'}`}>
                                                    <div className="modal-checkbox pr-2 mb-3 mb-xl-2 pb-1 pb-xl-1">
                                                        <input id="columnQuantity"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnQuantity"
                                                               checked={this.state.columnQuantity.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnQuantity.disabled}
                                                        />
                                                        <label htmlFor="columnQuantity">{t('quantityWord')}</label>
                                                    </div>
                                                    <div className="modal-checkbox pr-2 mb-3 mb-xl-2 pb-1 pb-xl-1">
                                                        <input id="columnPrice"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnPrice"
                                                               checked={this.state.columnPrice.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnPrice.disabled}
                                                        />
                                                        <label htmlFor="columnPrice">{t('priceWord')}, {currencyToRender}.</label>
                                                    </div>
                                                    <div className="modal-checkbox pr-2 mb-3 mb-xl-2 pb-1 pb-xl-1">
                                                        <input id="columnSum"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnSum"
                                                               checked={this.state.columnSum.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnSum.disabled}
                                                        />
                                                        <label htmlFor="columnSum">{t('sumWord')}, {currencyToRender}.</label>
                                                    </div>
                                                    <div className="modal-checkbox">
                                                        <input id="columnDiscount"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnDiscount"
                                                               checked={this.state.columnDiscount.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnDiscount.disabled}
                                                        />
                                                        <label htmlFor="columnDiscount">{t('discountWord')}, %</label>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex d-xl-block flex-wrap w-50">
                                                    <div className="modal-checkbox pr-2 mb-3 mb-xl-2 pb-1 pb-xl-1">
                                                        <input id="columnPriceWithDiscount"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnPriceWithDiscount"
                                                               checked={this.state.columnPriceWithDiscount.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnPriceWithDiscount.disabled}
                                                        />
                                                        <label htmlFor="columnPriceWithDiscount">{t('priceWithDiscount')}, {currencyToRender}.</label>
                                                    </div>
                                                    <div className="modal-checkbox pr-2 mb-3 mb-xl-2 pb-1 pb-xl-1">
                                                        <input id="columnSumWithDiscount"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnSumWithDiscount"
                                                               checked={this.state.columnSumWithDiscount.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnSumWithDiscount.disabled}
                                                        />
                                                        <label htmlFor="columnSumWithDiscount">{t('sumWithDiscount')}, {currencyToRender}.</label>
                                                    </div>
                                                    <div className={`modal-checkbox ${this.state.isPartner && this.state.columnView ? 'column-for-partner' : ''}`}>
                                                        <input id="columnBonus"
                                                               type="checkbox"
                                                               name="pdf-spec-columns"
                                                               value="columnBonus"
                                                               checked={this.state.columnBonus.checked}
                                                               onChange={this.PDFSpecColumns}
                                                               disabled={this.state.columnBonus.disabled}
                                                        />
                                                        <label htmlFor="columnBonus">{t('bonusesWord')}, {currencyToRender}.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="settings-section-name semi-bold mb-2">
                                            {t(this.state.isPartner ? 'addTitlePage' : 'logoOnFirst')}
                                        </div>
                                        <div className="d-md-flex mb-4 pb-2">
                                            {this.state.isPartner ?
                                                <div className="modal-checkbox">
                                                    <input id="logo-checkbox"
                                                           type="checkbox"
                                                           name="pdf-title-page"
                                                           value="addTitlePage"
                                                           checked={this.state.pdfTitlePage}
                                                           onChange={this.PDFTitlePage}
                                                    />
                                                    <label htmlFor="logo-checkbox">{t('yesWord')}</label>
                                                </div>
                                                :
                                                <>
                                                    <div className="mr-4 pr-2 mb-3 mb-md-0 mb-xl-2 pb-1 pb-md-0 pb-xl-1">
                                                        <input id="mirsLogo" type="radio" name="pdf-logo" value="mirs" checked={this.state.pdfLogo === 'mirs'} onChange={this.PDFTitleLogo} className="mr-2 pointer" />
                                                        <label htmlFor="mirsLogo" className="pointer mb-0">MIRS</label>
                                                    </div>
                                                    <div className=" mr-4 pr-2 mb-3 mb-md-0 mb-xl-2 pb-1 pb-md-0 pb-xl-1">
                                                        <input id="mirsHomeLogo" type="radio" name="pdf-logo" value="mirshome" checked={this.state.pdfLogo === 'mirshome'} onChange={this.PDFTitleLogo} className="mr-2 pointer" />
                                                        <label htmlFor="mirsHomeLogo" className="pointer mb-0">MIRSHOME</label>
                                                    </div>
                                                    <div className=" mr-4 pr-2 mb-3 mb-md-0 mb-xl-2 pb-1 pb-md-0 pb-xl-1">
                                                        <input id="withoutLogo" type="radio" name="pdf-logo" value="" checked={this.state.pdfLogo === ''} onChange={this.PDFTitleLogo} className="mr-2 pointer" />
                                                        <label htmlFor="withoutLogo" className="pointer mb-0">Без лого</label>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="floating-label position-relative mb-4 w-100">
                                                    <textarea id="notes-text"
                                                              rows="3"
                                                              className="w-100 has-value"
                                                              value={this.state.notesText}
                                                              onChange={this.changeNotesText}
                                                              style={{resize: 'none'}}
                                                    />
                                            <label htmlFor="notes-text" className="text-gray-30 position-absolute mb-0">
                                                {t('notesWord')}
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="btn btn-primary semi-bold make-pdf position-relative" onClick={!this.state.isRequest ? this.generatePDF : undefined}>
                                            {this.state.isRequest && <LoaderBtn/>}
                                            {t('downloadPdf')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currency: state.getCurrencyReducer
    }
};

export default withTranslation()(connect(mapStateToProps)(SettingsModal));