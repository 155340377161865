import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function ZeroFooter() {
    const { t } = useTranslation();

    return (
        <footer className="zero-footer">
            <div className="container">
                <div className="d-md-flex align-items-center justify-content-between">
                    <svg className="svg-icon mb-3 mb-md-0">
                        <use xlinkHref="/images/sprite.svg#logo-business-icon"></use>
                    </svg>
                    <div>© {new Date().getFullYear()} {t('copyright')}</div>
                </div>
            </div>
        </footer>
    );
}