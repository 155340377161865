import React, { useEffect, useState} from 'react'
import { Redirect, Route } from 'react-router-dom'
import {isAuthorizedUser} from "../../helper";

export default function AuthRoute({ component: Component, ...rest }) {
    const [seoData, setSeoData] = useState({});

    useEffect(() => {
        rest.seoCallback(seoData);
    }, [seoData]);

    function setSeo(seoData) {
        setSeoData(seoData);
    }

    return (
        <Route {...rest} render={props => ((localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) && isAuthorizedUser()) ?
            <Redirect to={{pathname: "/", state: { from: props.location }}} />
            :
            <Component {...props} seoCallback={setSeo} />
        }
        />
    )
}