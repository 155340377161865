import { connect } from "react-redux";
import { getCartIfNeeded } from "../actions";
import Header from "../components/Header";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {isAuthorizedUser} from "../helper";

const mapStateToProps = state => {
    return {
        cartCounter: state.cart.cartCounter,
        compareCounter: state.compare.compareCounter,
        globalPartner: state.selectedOptions.globalPartner
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onShowCartModal: () => {
            dispatch(getCartIfNeeded());
        },
        dispatch
    }
};
const translatedHeader = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));

export default withRouter(translatedHeader);
