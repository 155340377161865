import React, {useEffect, useState} from "react";
import {checkLength, clearError, hiddenScroll} from "../../helper";
import {useTranslation} from "react-i18next";
import $ from 'jquery';
import {API_URL} from "../../constant";
import axios from '../../axiosInstance';
import LoaderBtn from "../partials/LoaderBtn";

export default function SettingsPDFHorecaModal() {
    const { t } = useTranslation();
    const [officeInputValue, setOfficeInputValue] = useState('');
    const [errorsObj, setErrorsObj] = useState({});
    const [isRequest, setIsRequest] = useState(false);

    useEffect(() => {
        $('#create-modal').on('shown.bs.modal', () => {
            hiddenScroll(true);
        }).on('hidden.bs.modal', () => {
            hiddenScroll(false);
        })
    }, []);

    function pressEnterRunSubmit(e) {
        (e.key === 'Enter' && !isRequest) && generateHorecaPDF();
    }

    function generateHorecaPDF() {
        const data = {
            project_id: JSON.parse(localStorage.getItem('selectionDataForPdf')).project_id,
            storage_id: JSON.parse(localStorage.getItem('selectionDataForPdf')).storage_id,
            order_id: JSON.parse(localStorage.getItem('selectionDataForPdf')).order_id,
            productsSpec: 1,
            orientation: 'landscape',
            isHoreca: true,
            regionOffice: officeInputValue.trim(),
        }

        axios.post(`${API_URL}/front/selection/get-data-pdf`, data, {
            responseType: 'blob'
        })
            .then(res => {
                setIsRequest(false);

                //Create a Blob from the PDF Stream
                const file = new Blob([res.data], { type: res.headers['content-type'] });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', `${res.headers['content-disposition'].split('=')[1]}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                setIsRequest(false);
                console.log(error);
            });
    }

    return (
        <div id="setting-horeca-pdf-modal" className="modal custom-scroll" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content custom-scroll">
                    <div className="modal-body d-flex">
                        <div className="d-flex flex-column w-100">
                            <div className="settings-modal-header position-relative">
                                <button type="button"
                                        className="btn bg-transparent position-absolute"
                                        data-dismiss="modal"
                                />
                                <h2 className="mb-4 text-black">{t('pdfSettings')}</h2>
                                <button type="button"
                                        className="settings-modal-close d-none"
                                        data-toggle="modal"
                                        data-target="#cart-selection-modal"
                                        data-dismiss="modal"
                                ></button>
                            </div>
                            <div className="d-flex flex-column flex-grow-1">
                                <div className="floating-label mb-4 position-relative">
                                    <input id="region-manager-input"
                                           className={`w-100 ${officeInputValue !== '' ? 'has-value' : ''}`}
                                           type="text"
                                           value={officeInputValue}
                                           onChange={(e) => setOfficeInputValue(e.target.value)}
                                           onFocus={() => setErrorsObj(clearError(errorsObj,'regionOffice'))}
                                           onBlur={() => officeInputValue !== '' && setErrorsObj(checkLength(errorsObj, 'regionOffice', officeInputValue, 1, 100))}
                                           onKeyDown={pressEnterRunSubmit}
                                    />
                                    <label htmlFor="create-project-input" className="text-gray-30 position-absolute mb-0">
                                        {t('regionOffice')}
                                    </label>
                                    {errorsObj.regionOffice &&
                                        <span className="error-msg position-absolute">
                                    {errorsObj.regionOffice === 'required' ?
                                        t('notBeEmpty')
                                        : errorsObj.regionOffice === 'back' ?
                                            t('fakeNameOfProject')
                                            :errorsObj.regionOffice === 'min' ?
                                                t('enterMin1')
                                                :
                                                t('max100')
                                    }
                                </span>
                                    }
                                </div>
                                <div>
                                    <div className="btn btn-primary semi-bold make-pdf position-relative" onClick={!isRequest ? generateHorecaPDF : undefined}>
                                        {isRequest && <LoaderBtn/>}
                                        {t('downloadPdf')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
