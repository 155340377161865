import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function CartSelectionCounter() {
    const { t } = useTranslation();
    const cartCounter = useSelector(state => state.cartSelection.counter, shallowEqual);

    return (
        <div className="header-cart-wrapper header-action pointer d-flex flex-column align-items-center position-relative" data-toggle="modal" data-target="#cart-selection-modal">
            <svg className="svg-icon">
                <use xlinkHref="/images/sprite.svg#shopping-bag"></use>
            </svg>
            {cartCounter > 0 && <div className="header-action__counter d-flex justify-content-center align-items-center semi-bold">{cartCounter}</div>}
            <span className="header-action__text">{t('selectionWord')}</span>
        </div>
    );
}