import axios from "../axiosInstance";
import {managerId, storageProject, storageSelection} from "../constant";
import {API_URL} from "../constant";
import {setAxiosSettings} from "../helper";

// Cart actions
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_COUNTER = 'UPDATE_CART_COUNTER';
export const CHANGE_NEEDS_UPDATE = 'CHANGE_NEEDS_UPDATE';
export const ADD_PRODUCT_ID = 'ADD_PRODUCT_ID';
export const CHANGE_REQUEST_FLAG_CART = 'CHANGE_REQUEST_FLAG_CART';

function updateCart(productGroups, productIds, cartTotal) {
    return { type: UPDATE_CART, productGroups, productIds, cartTotal };
}

function changeNeedsUpdate(flag) {
    return { type: CHANGE_NEEDS_UPDATE, flag }
}

function updateCartCounter(cartCounter) {
	return { type: UPDATE_CART_COUNTER, cartCounter };
}

function addProductId(addedId) {
    return { type: ADD_PRODUCT_ID, addedId };
}

function changeRequestFlagCart(flag) {
    return { type: CHANGE_REQUEST_FLAG_CART, flag };
}

export function addToCart(id, projectId, storageId) {
    return function(dispatch) {
        const data = {
            'product_id': id,
            'storage_id': storageId,
            'project_id': projectId,
            'bulk-import': 0
        };

        axios.post(`${API_URL}/front/selection/product/add`, data)
            .then(res => {
                dispatch(getCartSelection(projectId, storageId));
            })
            .catch(function (error) {
                console.log(error);
            });

        // старая логика добавления
        /*axios.put(`/front/cart/product/${id}`)
            .then(res => {
                dispatch(getCartCounter());
                dispatch(addProductId(id));
                dispatch(changeNeedsUpdate(true));
            })
            .catch(function (error) {
                console.log(error);
            });*/
    }
}

function getCart() {
    return function(dispatch) {
        axios.get(`${API_URL}/front/cart/show`)
            .then(res => {
                dispatch(updateCart(res.data.data, res.data.itemsIds, res.data.total));
                dispatch(changeNeedsUpdate(false));
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}

function shouldGetCart(state) {
    return state.cart.needsUpdate;
}

export function getCartIfNeeded() {
    return (dispatch, getState) => {
        if(shouldGetCart(getState())) {
            return dispatch(getCart());
        }
    }
}

export function getCartCounter() {
    return function(dispatch) {
        axios.get(`${API_URL}/front/cart/count`)
            .then(res => {
                dispatch(updateCartCounter(res.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function removeFromCart(id, projectId, storageId) {
    return function(dispatch) {
        axios.delete(`${API_URL}/front/selection/product/delete`, {
            data: {
                product_id: id,
                storage_id: storageId,
                project_id: projectId
            }
        })
            .then(res => {
                dispatch(getCartSelection(projectId, storageId));
            })
            .catch(function (error) {
                console.log(error);
            });

        // старая логика удаления
        /*axios.delete(`/front/cart/product/${id}`)
            .then(res => {
                dispatch(getCart());
                dispatch(getCartCounter());
            })
            .catch(function (error) {
                console.log(error);
            });*/
    }
}

export function destroyCart() {
    return function(dispatch) {
        axios.get(`${API_URL}/front/cart/destroy`)
            .then(res => {
                dispatch(getCart());
                dispatch(getCartCounter());
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function updateProductCount(id, quantity) {
    return function(dispatch) {
        dispatch(changeRequestFlagCart(true));

        axios.patch(`${API_URL}/front/cart/product/${id}?count=${quantity}`)
            .then(res => {
                dispatch(getCart());
                dispatch(getCartCounter());
                dispatch(changeRequestFlagCart(false));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function updateProductDiscount(id, discount) {
    return function(dispatch) {
        dispatch(changeRequestFlagCart(true));

        axios.patch(`${API_URL}/front/cart/product/${id}?discount=${discount}`)
            .then(res => {
                dispatch(getCart());
                dispatch(changeRequestFlagCart(false));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

// Compare actions
export const UPDATE_COMPARE = 'UPDATE_COMPARE';
export const UPDATE_COMPARE_COUNTER = 'UPDATE_COMPARE_COUNTER';
export const CHANGE_REQUEST_FLAG_COMPARE = 'CHANGE_REQUEST_FLAG_COMPARE';
export const ADD_PRODUCT_ID_TO_COMPARE = 'ADD_PRODUCT_ID_TO_COMPARE';

function updateCompare(productGroups, productIds) {
    return { type: UPDATE_COMPARE, productGroups, productIds };
}

function updateCompareCounter(compareCounter) {
    return { type: UPDATE_COMPARE_COUNTER, compareCounter };
}

function changeRequestFlagCompare(flag) {
    return { type: CHANGE_REQUEST_FLAG_COMPARE, flag }
}

function addProductIdToCompare(addedId) {
    return { type: ADD_PRODUCT_ID_TO_COMPARE, addedId };
}

export function getCompare(customerId) {
    return function(dispatch) {
        const getParam = customerId ? `?customer_id=${customerId}` : '';

        // eslint-disable-next-line
        axios.get(`${API_URL}/front/compare/show${location.search}${getParam}`)
            .then(res => {
                dispatch(updateCompare(res.data.data, res.data.itemsIds));
                dispatch(changeRequestFlagCompare(false));
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}

export function getCompareCounter() {
    return function(dispatch) {
        axios.get(`${API_URL}/front/compare/count`)
            .then(res => {
                dispatch(updateCompareCounter(res.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function addToCompare(id) {
    return function(dispatch) {
        dispatch(changeRequestFlagCompare(true));
        axios.put(`${API_URL}/front/compare/product/${id}`)
            .then(res => {
                dispatch(getCompareCounter());
                dispatch(addProductIdToCompare(id));
                dispatch(getCompare());
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function removeFromCompare(id) {
    return function(dispatch) {
        dispatch(changeRequestFlagCompare(true));
        axios.delete(`${API_URL}/front/compare/product/${id}`)
            .then(res => {
                dispatch(getCompare());
                dispatch(getCompareCounter());
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function removeFromCompareGroup(groupId) {
    return function(dispatch) {
        dispatch(changeRequestFlagCompare(true));
        axios.get(`${API_URL}/front/compare/destroy/${groupId}`)
            .then(res => {
                dispatch(getCompare());
                dispatch(getCompareCounter());
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

// ============================= favorite actions   =============================
export const ADD_PRODUCT_ID_TO_FAVORITE = 'ADD_PRODUCT_ID_TO_FAVORITE';
export const REMOVE_PRODUCT_ID_FROM_FAVORITE = 'REMOVE_PRODUCT_ID_FROM_FAVORITE';

function addProductIdToFavorite(addedId) {
    return { type: ADD_PRODUCT_ID_TO_FAVORITE, addedId };
}

function removeProductIdFromFavorite(idToRemove) {
    return { type: REMOVE_PRODUCT_ID_FROM_FAVORITE, idToRemove };
}

export function addToFavorite(id) {
    return function(dispatch) {
        axios.put(`${API_URL}/front/favorite`, {product_id: id})
            .then(res => {
                dispatch(addProductIdToFavorite(id));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function removeFromFavorite(id) {
    return function(dispatch) {
        axios.delete(`${API_URL}/front/favorite`, { data: { product_id: id }})
            .then(res => {
                if (res.status === 200) {
                    dispatch(removeProductIdFromFavorite(id));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function updateFavoriteInitialState(id) {
    return function (dispatch) {
        dispatch(addProductIdToFavorite(id));
    }
}

// ============================= other   =============================
export const UPDATE_CREATE_RENAME_MODAL = 'UPDATE_CREATE_RENAME_MODAL';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const CUSTOMERS_REQUEST_LOADER = 'CUSTOMERS_REQUEST_LOADER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER_PROJECTS = 'GET_CUSTOMER_PROJECTS';
export const SELECTED_PARTNER = 'SELECTED_PARTNER';
export const SELECTED_PROJECT = 'SELECTED_PROJECT';
export const SELECTED_SELECTION = 'SELECTED_SELECTION';
export const NEED_UPDATE_CART_SELECTION = 'NEED_UPDATE_CART_SELECTION';
export const UPDATE_CART_SELECTION_COUNTER = 'UPDATE_CART_SELECTION_COUNTER';
export const GET_PRODUCT_ID_CART_SELECTION = 'GET_PRODUCT_ID_CART_SELECTION';
export const GET_CART_SELECTION = 'GET_CART_SELECTION';
export const UPDATE_MANAGER_ID = 'UPDATE_MANAGER_ID';
export const PRICE_NEED_UPDATE = 'PRICE_NEED_UPDATE';
export const DUPLICATE_SELECTION = 'DUPLICATE_SELECTION';
export const NEW_PROJECT_IN_DUPLICATE_SELECTION = 'NEW_PROJECT_IN_DUPLICATE_SELECTION';
export const NEED_UPDATE_SELECTIONS_PAGE = 'NEED_UPDATE_SELECTIONS_PAGE';
export const EXCHANGE_DATA = 'EXCHANGE_DATA';
export const GET_DIRECTIONS_DATA = 'GET_DIRECTIONS_DATA';
export const HAS_ACTIONS = 'HAS_ACTIONS';
export const GET_CURRENCY = 'GET_CURRENCY';

export function updateCreateRenameModal(modalData) {
    return function(dispatch) {
        dispatch({ type: UPDATE_CREATE_RENAME_MODAL, data: modalData });
    };
}

export function updateSelections(needUpdate, partnerId, projectId) {
    return function(dispatch) {
        dispatch({ type: UPDATE_SELECTION, needUpdate: needUpdate, partnerId: partnerId, projectId: projectId });
    };
}

export function updateSelectionsPage(flag) {
    return function(dispatch) {
        dispatch({ type: NEED_UPDATE_SELECTIONS_PAGE, needUpdate: flag });
    };
}

export function updateManagerId(managerId) {
    return function(dispatch) {
        dispatch({ type: UPDATE_MANAGER_ID, managerId: managerId });
    };
}

export function getCustomers(managerId) {
    return function(dispatch) {
        // run loader before we get customers
        dispatch({ type: CUSTOMERS_REQUEST_LOADER, customersLoader: true });

        axios.get(`${API_URL}/front/selection/get_customers?manager_id=${managerId}`)
            .then(res => {
                dispatch({ type: GET_CUSTOMERS, customers: res.data.data.customerData });
                dispatch({ type: CUSTOMERS_REQUEST_LOADER, customersLoader: false });
            })
            .catch(function (error) {
                console.log(error);
                dispatch({ type: CUSTOMERS_REQUEST_LOADER, customersLoader: false });
            });
    }
}

export function getSelectedCustomerProjects(customerId, managerId) {
    return function(dispatch) {
        axios.get(`${API_URL}/front/selection/get_projects?manager_id=${managerId}&customer_id=${customerId}`)
            .then(res => {
                const projects = res.data.data.projects;
                dispatch({ type: GET_CUSTOMER_PROJECTS, globalPartnerProjects: projects });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function updateSelectedPartner(partnerData) {
    return function(dispatch) {
        localStorage.removeItem('selectedGlobalProject');
        localStorage.removeItem('selectedGlobalSelection');
        // Если partnerData не пустой - то заполняем на будущее, иначе просто удаляем
        // Запоминать надо чтобы при обновлении страницы не терялся выбранны партнер и
        // не сбрасывался на пустое значение
        if(partnerData?.id) {
            localStorage.setItem('selectedGlobalPartner', JSON.stringify({
                id: partnerData.id,
                name: partnerData.name
            }));
        }
        else {
            localStorage.removeItem('selectedGlobalPartner');
        }
        dispatch({type: PRICE_NEED_UPDATE, priceNeedUpdate: true});
        dispatch({type: SELECTED_PARTNER, globalPartner: partnerData});
        dispatch({type: SELECTED_PROJECT, globalProject: {}});
        dispatch({type: SELECTED_SELECTION, globalSelection: {}});
    };
}

export function updateSelectedProject(projectData) {
    return function(dispatch) {
        localStorage.setItem('selectedGlobalProject', JSON.stringify({id: projectData.id, name: projectData.name}));
        dispatch({type: SELECTED_PROJECT, globalProject: projectData});
    };
}

export function updateSelectedSelection(selectionData) {
    return function(dispatch) {
        localStorage.setItem('selectedGlobalSelection', JSON.stringify({id: selectionData.id, name: selectionData.name}));
        dispatch({type: SELECTED_SELECTION, globalSelection: selectionData});
    };
}

export function duplicateSelectionData(selectionId, selectionName, orderId) {
    return function(dispatch) {
        dispatch({type: DUPLICATE_SELECTION, selectionId: selectionId, selectionName: selectionName, orderId: orderId});
    };
}

export function newProjectInDuplicate(projectData) {
    return function(dispatch) {
        dispatch({type: NEW_PROJECT_IN_DUPLICATE_SELECTION, newProjectInDuplicate: projectData});
    };
}

export function getCartSelection(projectId, storageId) {
    return function(dispatch) {
        if(storageId === undefined) {
            dispatch({type: UPDATE_CART_SELECTION_COUNTER, counter: 0});
            dispatch({type: GET_CART_SELECTION, cartSelection: {}});
            dispatch({type: GET_PRODUCT_ID_CART_SELECTION, productsId: []});

            return;
        }

        let getParam = `project_id=${projectId}&storage_id=${storageId}`;

        axios.get(`${API_URL}/front/selection/get_selection?${getParam}`)
            .then(res => {
                const response = res.data.data;
                let productsInCartSelection = [];

                response.products.map(category => {
                    category.items.map(product => {
                        productsInCartSelection.push(product.id);
                    })
                })

                dispatch({type: GET_CURRENCY, currency: response.currency});
                dispatch({type: UPDATE_CART_SELECTION_COUNTER, counter: response.productsCount});
                dispatch({type: GET_CART_SELECTION, cartSelection: response});
                dispatch({type: GET_PRODUCT_ID_CART_SELECTION, productsId: productsInCartSelection});
            })
            .catch(function (error) {
                console.log(error);
                dispatch({type: UPDATE_CART_SELECTION_COUNTER, counter: 0});
                dispatch({type: GET_CART_SELECTION, cartSelection: {}});
                dispatch({type: GET_PRODUCT_ID_CART_SELECTION, productsId: []});
            });
    };
}

export function getExchangeCurrency() {
    return function(dispatch) {
        axios.get(`${API_URL}/front/exchange-currency`)
            .then(res => {
                const response = res.data.data;

                dispatch({type: EXCHANGE_DATA, exchangeData: response});
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}

export function getDirections(customerId, label='') {
    return function(dispatch) {
        const getParam = customerId ? `?customer_id=${customerId}${label ? '&label='+label : ''}` : `${label ? '?label='+label : ''}`;
        // console.log(`dispatch getDirections START: ${label}`);
        axios.get(`${API_URL}/front/get-directions${getParam}`)
            .then(res => {
                const menuData = {};
                let isDishesUser = false;

                if (res.data.directions.find(el => el.slug === 'posuda')) isDishesUser = true;

                dispatch({type: GET_DIRECTIONS_DATA, menuData: res.data.directions, isDishesUser});
                dispatch({type: HAS_ACTIONS, hasActions: res.data.hasActions});
                // console.log(`dispatch getDirections COMPLETE: ${label}`);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}

export function getCurrency(currency) {
    return function(dispatch) {
        dispatch({type: GET_CURRENCY, currency});
    };
}

export function setAnonymousDirection(targetDirection) {
    return function(dispatch) {
        const direction = targetDirection || localStorage.getItem('userDirection') || 'HORECA';
        localStorage.setItem('userDirection', targetDirection);
        axios.post(`${API_URL}/front/set-anonymous-direction`, {direction})
            .then(res => {
                const response = res.data;
                const locale = localStorage.getItem('userLocale') || 'ua';

                sessionStorage.setItem('authToken', response.api_token);
                sessionStorage.setItem('isHorecaUser', response.isHoreca);
                sessionStorage.setItem('managerId', response.manager_id);
                sessionStorage.setItem('canChangePrice', false);

                // Global axios settings for further requests
                setAxiosSettings(response.api_token, locale);
                // set managerId for using all around
                dispatch(updateManagerId(response.manager_id));
                localStorage.setItem('userType', res.data.type);
                localStorage.removeItem('settingsModal');
            });
    };
}

export function setPublicUserDirection(direction, label='') {
    // console.log(`setPublicUserDirection direction: ${direction} ${label}`);
    return function (dispatch) {
        dispatch({ type: 'SET_USER_DIRECTION', direction });
    }
}

export function getMobileFilters(flag) {
    return function (dispatch) {
        if(flag) {
            document.body.classList.add('hidden-scroll-xl');
        } else {
            document.body.classList.remove('hidden-scroll-xl');
        }

        dispatch({ type: 'SHOW_MOBILE_FILTERS', isMobileFiltersOpened: flag });
    }
}

export function updateFavoritesPage(flag) {
    return function (dispatch) {
        dispatch({ type: 'UPDATE_FAVORITES', needUpdate: flag });
    }
}