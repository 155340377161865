import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DEFAULT_LOCALE} from "../../../../constant";

export default function ZeroHeader() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [locale, setLocale] = useState(localStorage.getItem('userLocale') || DEFAULT_LOCALE);

    function changeLocale(locale) {
        if(localStorage.getItem('userLocale') === locale) return;

        i18n.changeLanguage(locale);
        localStorage.setItem('userLocale', locale);
        setLocale(locale);
    }

    return (
        <header className="zero-header">
            <div className="d-flex align-items-center justify-content-between">
                <svg className="svg-icon">
                    <use xlinkHref="/images/sprite.svg#logo-business-icon"></use>
                </svg>
                <div className="right-block d-flex align-items-center">
                    <a href="tel:0800404999" className="text-decoration-none d-none d-md-inline">0 (800) 404–999</a>
                    <a href="mailto:contacts@mirs.com.ua" className="text-decoration-none d-none d-xl-inline">contacts@mirs.com.ua</a>
                    <div className="lang-dropdown dropdown my-auto">
                        <div className="lang-dropdown__btn pointer" data-toggle="dropdown">
                            {locale === 'ru' ? 'Ру' : 'Укр'}
                            <div className="custom-dropdown__arrow position-absolute"></div>
                        </div>
                        <div className="lang-dropdown__list dropdown-menu">
                            <div className="lang-dropdown__list-item position-relative pointer"
                                 onClick={() => changeLocale('ua')}
                            >
                                Українською
                            </div>
                            <div className="lang-dropdown__list-item position-relative pointer"
                                 onClick={() => changeLocale('ru')}
                            >
                                Русский
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}