import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import {hiddenScroll, logoutClear} from "../../helper";
import { useLocation } from 'react-router-dom';
import {updateSelectedPartner} from "../../actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

export default function CabinetMenu({sideMenu}) {
    const { t } = useTranslation();
    const location = useLocation();
    const [isCabinetMenuOpen, setIsCabinetMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const userType = +localStorage.getItem('userType');
    const cabinetMenu = [
        {name: t('personalData'), slug: 'personal-data', icon: 'my-data', show: true},
        {name: t('managerData'), slug: 'manager-data', icon: 'manager-data', show: userType === 1 || userType === 2},
        {name: t('companyData'), slug: 'company-data', icon: 'company-data', show: userType === 2},
        {name: t('ordersWord'), slug: 'orders', icon: 'orders', show: userType !== 3},
        {name: t('selectionsWord'), slug: 'selections', icon: 'selections', show: userType !== 3},
        {name: t('favoriteWord'), slug: 'favorites', icon: 'favorite', show: true},
        {name: t('notificationsWord'), slug: 'notifications', icon: 'notifications', show: false},
        {name: t('clientsWord'), slug: 'clients', icon: 'clients', show: false},
        {name: t('managersWord'), slug: 'managers', icon: 'managers', show: false},
    ];

    useEffect(() => {
        const mobileCheck = () => {
            if(window.innerWidth > 1199 && isCabinetMenuOpen) {
                hiddenScroll(false);
                setIsCabinetMenuOpen(false);
            }
        };

        window.addEventListener('resize', mobileCheck);
        window.addEventListener('orientationchange', mobileCheck);

        return () => {
            window.removeEventListener('resize', mobileCheck);
            window.removeEventListener('orientationchange', mobileCheck);
        }
    }, []); // Will fire only once

    // toDo Сейчас компонент ожидает увидеть себя же на следующей странице. Из за этого проблемы с хидден скролом при переходе по ссылке избранного. Надо пофиксить логику перехода по ссылкам на которых не присутствует текущий компонент. Сейчас если перейти на такой, то появляется хидден скрол. Тут пофикшено, но появился скрол в моб меню этого компонента Бургер -> Особистий кабінет -> Особистий кабінет
    useEffect(() => hiddenScroll(false), [isCabinetMenuOpen]);


    function closeSideMenu() {
        setIsCabinetMenuOpen(!isCabinetMenuOpen);
        whiteBreadcrumbs(!isCabinetMenuOpen);
    }

    function whiteBreadcrumbs(flag) {
        if(flag) {
            document.body.classList.add('white-breadcrumbs');
        } else {
            document.body.classList.remove('white-breadcrumbs');
        }
    }

    return (
        <nav className="cabinet-menu">
            {sideMenu &&
                <div className="cabinet-menu__toggle w-100 d-flex justify-content-between align-items-center"
                     onClick={closeSideMenu}
                >
                    <span className="ml-3">{t('myCabinetWord')}</span>
                    <svg className="svg-icon close mr-3">
                        <use xlinkHref="/images/sprite.svg#close"></use>
                    </svg>
                    <svg className="svg-icon open mr-3">
                        <use xlinkHref="/images/sprite.svg#left-arrow-2-icon"></use>
                    </svg>
                </div>
            }
            <div className={`cabinet-menu__inner ${isCabinetMenuOpen ? 'open' : ''} ${sideMenu ? 'px-3 px-md-0 pt-3 pt-md-0' : ''}`}>
                {cabinetMenu.map((item, index) => {
                    const slug = `/cabinet/${item.slug}`;

                    if (item.show === true) {
                        return (
                            <div key={`cabinet-menu-${index}`}
                                 className={`cabinet-menu__inner-item d-flex align-items-center px-3 position-relative ${location.pathname === slug ? 'selected rounded' : ''}`}
                                 onMouseEnter={e => { // костыль из за бага ховера -> CTL-1887
                                     if (e.target.classList.contains("fake-link-block")) {
                                         e.target.parentNode.classList.add('hovered')
                                     }
                                 }}
                                 onMouseLeave={e => e.target.parentNode.classList.remove('hovered')}
                            >
                                {location.pathname !== slug &&
                                    <Link to={slug}
                                          className="fake-link-block"
                                          onClick={closeSideMenu}
                                    />
                                }
                                <svg className="svg-icon mr-3 flex-shrink-0 mb-0">
                                    <use xlinkHref={`/images/sprite.svg#${item.icon}-icon`}></use>
                                </svg>
                                <span>{item.name}</span>
                            </div>
                        )
                    }
                })}
                <div className="cabinet-menu__inner-item cabinet-menu__inner-item position-relative exit my-2 p-1 pointer"
                     onClick={(evt) => {
                        evt.preventDefault();
                        logoutClear();
                        dispatch(updateSelectedPartner({}));
                         // Мы делаем именно так чтобы очистить установленные изначально значения в src/js/constant.js
                         // иначе у нас в константах сохраняются значения из локалсторадж на момент последней загрузки
                         // страницы и мы получаем бардак при логине ПП.
                        setTimeout(() => { window.location.href="/" }, 0);
                    }}
                >
                    {/*<Link to="/" className="fake-link-block" />*/}
                    <span className="d-none d-md-block">{t('exitWord')}</span>
                    <span className="d-md-none">{t('exitFromAccount')}</span>
                </div>
            </div>
        </nav>
    )
}
