import React, {Component, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import SideMenu from "./SideMenu";
import {getDirections, setPublicUserDirection} from "../actions";
import {APP_ENVIRONMENT, DEFAULT_LOCALE, DEFAULT_PUBLIC_DIRECTION, IS_PRODUCTION_ENVIRONMENT} from "../constant";
import LangDropdown from "./partials/LangDropdown";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import HeaderDesktopMenu from "./partials/HeaderDesktopMenu";
import DirectionDropdown from "./partials/DirectionDropdown";
import {useTranslation} from "react-i18next";
import {setAxiosSettings} from "../helper";

export default function PublicHeader({compareCounter}) {
    const { t } = useTranslation();
    const [localeName, setLocalName] = useState(localStorage.getItem('userLocale') || DEFAULT_LOCALE);
    const sideMenuData = useSelector(state => state.getDirectionsData.menuData, shallowEqual);
    const publicDirection = useSelector(state => state.publicDirectionReducer.userPublicDirection);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // console.log(`PublicHeader useEffect publicDirection: ${publicDirection}, authToken: ${localStorage.getItem('authToken')}`);
        /* Если переходин на страницу направления - просто обновляем меню. Это единственное место где можно его нормально обновить чтобы везде работало */
        if(location.pathname.includes('direction/') ) {
            // console.log(`PublicHeader useEffect publicDirection Direction component! ${publicDirection}`);
            if(['BT','HORECA'].includes(publicDirection)) {
                dispatch(getDirections(undefined, 'header_direction_load'));
            }
            return;
        }
        /* При переключении направления из DirectionDropdown проверяем корректность выбранного направления и обновляем данные
        * про Bearer токен в localStorage, axios для запросов на сервер. Потом обновляем меню для нового направления.
        */
        if(['BT','HORECA'].includes(publicDirection)) {
            const authToken = localStorage.getItem('authToken');
            let token = '';
            if(authToken !== `anonymous_${publicDirection.toLowerCase()}`) {
                if (authToken === '') {
                    token = `anonymous_${publicDirection.toLowerCase()}`;
                } else if (authToken === 'anonymous_bt' && publicDirection === 'HORECA') {
                    token = 'anonymous_horeca';
                } else if (authToken === 'anonymous_horeca' && publicDirection === 'BT') {
                    token = 'anonymous_bt';
                }
            }
            else {
                token = authToken;
            }
            const locale = localStorage.getItem('userLocale') || 'ua';
            localStorage.setItem('authToken', token);
            localStorage.setItem('isHorecaUser', publicDirection === 'HORECA');
            localStorage.setItem('userDirection', publicDirection);
            setAxiosSettings(token, locale);

            dispatch(getDirections(undefined, 'header_direction_update'));
        }
        else {
            // console.log(`PublicHeader useEffect publicDirection IGNORE: ${publicDirection}`);
        }
    },[publicDirection]);

    useEffect(() => {
        // console.log(`PublicHeader onload: ${publicDirection}, ${localStorage.getItem('userDirection')}`);
        /* Обрабатываем "прямой вход" анонимного пользователя. При этом в redux ничего нет и берем направление из localstorage
        * Если входим по роуту на страничках, то просто обновляем меню ибо это на монтировании компонента один раз.
        *  */
        if(publicDirection === '') {
            let direction = localStorage.getItem('userDirection');
            if(['BT','HORECA'].includes(direction)) {
                dispatch(setPublicUserDirection(direction, 1));
            }
            else {
                dispatch(setPublicUserDirection('', 2));
            }
        }
        else {
            // no sense to dispatch getDirections() if publicDirection just set. It was executed from useEffect by publicDirection above
            dispatch(getDirections(undefined,'header_else'));
        }
        const onLocaleChange = () => {
            dispatch(getDirections(undefined,'header_locale'));
        };
        i18n.on('languageChanged', onLocaleChange);
        return () => i18n.off('languageChanged', onLocaleChange);
    },[]);

    return (
        <React.Fragment>
            <header className="bg-white position-fixed w-100 anonymous-menu" style={{ height:'72px'}}>
                <div className="page-container">
                    <div className="d-flex align-items-center mt-md-3 mb-xxl-3">
                        <div className="d-flex align-items-center">
                            <SideMenu sideMenuData={sideMenuData}/>
                            <Link to="/" className="d-block mr-2">
                                <img src="/images/logo-mirs.svg" alt="MIRS logo" className="header-logo"/>
                            </Link>
                            {!IS_PRODUCTION_ENVIRONMENT && (
                            <div className="d-none d-md-block">
                                <DirectionDropdown/>
                            </div>
                            )}
                            <div className="d-none d-md-block">
                                <LangDropdown thisLocale={localeName}/>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end w-100">
                            <div className="header-wrapper d-flex align-items-center w-100">
                                <div className="header-wrapper__search-wrap d-flex">
                                    <HeaderSearch/>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div
                                    className="header-action pointer d-flex flex-column align-items-center position-relative">
                                    <Link to="/login" className="fake-link-block"/>
                                    <svg className="svg-icon">
                                        <use xlinkHref="/images/sprite.svg#user-auth"></use>
                                    </svg>
                                    <span className="header-action__text">{t('entryWord')}</span>
                                </div>
                                { publicDirection === 'HORECA' && (
                                    <div
                                        className="header-action pointer d-flex flex-column align-items-center position-relative">
                                        <a href={`https://${APP_ENVIRONMENT === 'development' ? 'dev.' : APP_ENVIRONMENT === 'stage' ? 'stage.' : ''}horeca.mirs.com.ua?registration=1`} className="fake-link-block"/>
                                        <svg className="svg-icon">
                                            <use xlinkHref="/images/sprite.svg#user-registration"></use>
                                        </svg>
                                        <span className="header-action__text">{t('registrationWord')}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <HeaderDesktopMenu anonymousMode={true}/>
        </React.Fragment>
    );
}