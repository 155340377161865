import axios from "axios";
import {logoutClear} from "./helper";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        // console.log(`axios.interceptors.request:`,config);
        return config;
    },
    error => {
        // Здесь можно обработать ошибки запроса
        console.error('Ошибка при отправке запроса:', error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        // Здесь можно изменить данные ответа перед их обработкой
        return response;
    },
    error => {
        if(error.response.status === 401) {
            logoutClear();
            window.location = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;