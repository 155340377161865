import React, {useEffect, useState} from "react";
import {checkLength, clearError, hiddenScroll} from "../../helper";
import {getSelectedCustomerProjects, updateCreateRenameModal, updateSelectionsPage} from "../../actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import PartnerDropdown from "../pages/cabinet/partials/PartnerDropdown";
import axios from "../../axiosInstance";
import {useHistory} from "react-router-dom";
import LoaderBtn from "./LoaderBtn";
import {useTranslation} from "react-i18next";
import $ from 'jquery';
import {API_URL} from "../../constant";

export default function DuplicationSelectionModal() {
    const { t } = useTranslation();
    const [errorsObj, setErrorsObj] = useState({});
    const [duplicateName, setDuplicateName] = useState('');
    const [projectInput, setProjectInput] = useState('');
    const [currentPartner, setCurrentPartner] = useState({});
    const [currentProjects, setCurrentProjects] = useState({});
    const [selectedProject, setSelectedProject] = useState({});
    const [isRequest, setIsRequest] = useState(false);
    const managerId = useSelector(state => state.getManagerId.managerId, shallowEqual);
    const selectedGlobalPartner = useSelector(state => state.selectedOptions.globalPartner, shallowEqual);
    const selectedGlobalProject = useSelector(state => state.selectedOptions.globalProject, shallowEqual);
    const selectedCustomerProjects = useSelector(state => state.selectedOptions.globalPartnerProjects, shallowEqual);
    const duplicateThisSelection = useSelector(state => state.duplicateSelection, shallowEqual);
    const newProjectInDuplicate = useSelector(state => state.duplicateSelection.newProjectInDuplicate, shallowEqual);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        $('#duplicate-selection-modal').on('shown.bs.modal', () => {
            hiddenScroll(true);
        }).on('hidden.bs.modal', () => {
            hiddenScroll(false);
        })
    }, []);

    useEffect(() => {
        const pressEscape = (e) => {
            e.key === 'Escape' && $('#duplicate-selection-modal').modal('hide');
        }

        window.addEventListener('keydown', pressEscape, true);

        return (
            window.removeEventListener('keydown', pressEscape)
        )
    }, []);

    useEffect(() => {
        setDuplicateName(`Копия - ${duplicateThisSelection.selectionName}`);
    }, [duplicateThisSelection]);

    useEffect(() => {
        getProjects();
    }, [currentPartner]);

    useEffect(() => {
        selectedGlobalPartner.id !== currentPartner.id && setCurrentPartner(selectedGlobalPartner);
    }, [selectedGlobalPartner]);

    useEffect(() => {
        if(selectedGlobalProject.name !== undefined) {
            setSelectedProject(selectedGlobalProject);
            setProjectInput(selectedGlobalProject.name);
        } else {
            setSelectedProject({});
            setProjectInput('');
        }
    }, [selectedGlobalProject]);

    useEffect(() => {
        setCurrentProjects(selectedCustomerProjects);
    }, [selectedCustomerProjects]);

    useEffect(() => {
        if(newProjectInDuplicate.id !== undefined) {
            setSelectedProject(newProjectInDuplicate);
            setProjectInput(newProjectInDuplicate.name);
        }
    }, [newProjectInDuplicate]);

    function getProjects() {
        if (currentPartner.id !== undefined) {
            axios.get(`${API_URL}/front/selection/get_projects?manager_id=${managerId}&customer_id=${currentPartner.id}`)
                .then(res => {
                    const projects = res.data.data.projects;

                    setCurrentProjects(projects);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    function createDuplicate() {
        // We don't need to validate form if any error present
        if(Object.keys(errorsObj).length > 0) return;

        let trimmedData = {
            duplicateName: duplicateName.trim(),
            project: projectInput.trim(),
            customer: currentPartner.name.trim()
        };

        let errors = {};

        // We need to set required error if any field is empty
        Object.keys(trimmedData).map(key => {
            if(trimmedData[key].length === 0) errors[key] = 'required';
        });

        // Add required errors or execute reCaptcha check
        if(Object.keys(errors).length > 0) {
            setErrorsObj(errors);
        } else {
            const data = {
                storage_id: duplicateThisSelection.selectionId,
                name: duplicateName.trim(),
                project_id: selectedProject.id,
                customer_id: currentPartner.id,
                order_id: duplicateThisSelection.orderId
            }

            setIsRequest(true);

            axios.post(`${API_URL}/front/selection/clone`, data)
                .then(res => {
                    dispatch(getSelectedCustomerProjects(currentPartner.id, managerId));
                    $('#duplicate-selection-modal').modal('hide');

                    history.location.pathname == '/cabinet/selections' && dispatch(updateSelectionsPage(true));

                    setIsRequest(false);
                })
                .catch(function (error) {
                    setIsRequest(false);
                    setErrorsObj({duplicateName: 'back'});
                    console.log(error);
                });
        }
    }

    return (
        <div id="duplicate-selection-modal" className="selections-modal modal fade pr-0" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-1">
                    <div className="modal-body py-2">
                        <div>
                            <div className="mt-1 mb-4 semi-bold">{t('copySelection2')}</div>
                            <div>
                                <div className="mb-3" onClick={() => setErrorsObj(clearError(errorsObj,'project'))}>
                                    <PartnerDropdown type="duplicate" selected={(partnerData) => {
                                        // console.log(partnerData)
                                        setCurrentPartner(partnerData);
                                        setSelectedProject({});
                                        setProjectInput('');
                                    }}/>
                                </div>
                                <div className="mb-3">
                                    <div id="selections-dropdown"
                                         className={`custom-dropdown dropdown ${currentPartner.id === undefined ? 'disabled' : ''}`}
                                         onClick={() => setErrorsObj(clearError(errorsObj,'project'))}
                                    >
                                        <div className="custom-dropdown__btn floating-label position-relative m-0 pointer" data-toggle="dropdown">
                                            <input id="copy-link"
                                                   className={`w-100 pointer text-ellipsis ${projectInput !== '' ? 'has-value' : ''}`}
                                                   type="text"
                                                   value={projectInput}
                                                   placeholder={t('noSelect')}
                                                   onChange={(e) => setProjectInput(e.target.value)}
                                                   readOnly
                                            />
                                            <label htmlFor="copy-link" className="text-gray-30 position-absolute mb-0">
                                                <span className="position-relative">{t('selectProject')}</span>
                                            </label>
                                            <div className="custom-dropdown__arrow position-absolute"></div>
                                        </div>
                                        <div className="custom-dropdown__list custom-scroll dropdown-menu w-100">
                                            <div className="add-new pointer" onClick={() => {
                                                dispatch(updateCreateRenameModal({
                                                    openModal: true,
                                                    type: 'createProjectInDuplicate',
                                                    partnerId: currentPartner.id,
                                                }));
                                                $('#create-modal').modal('show');
                                                $('#duplicate-selection-modal').modal('hide');
                                            }}>{t('createNewProject')}</div>
                                            {currentProjects.length > 0 &&
                                                currentProjects.map((project, i) => {
                                                    if (project.status != 1) return

                                                    return (
                                                        <div key={`custom-item-${i}`}
                                                             className={`project-item custom-dropdown__item pointer text-ellipsis ${selectedProject.id === project.project_id ? 'selected-filter' : ''}`}
                                                             onClick={() => {
                                                                 setProjectInput(project.name);
                                                                 setSelectedProject({id: project.project_id, name: project.name});
                                                             }}
                                                        >
                                                            {project.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="floating-label mb-4 position-relative">
                                    <input id="create-project-input"
                                           className={`w-100 ${duplicateName !== '' ? 'has-value' : ''}`}
                                           type="text"
                                           value={duplicateName}
                                           onChange={(e) => setDuplicateName(e.target.value)}
                                           onFocus={() => setErrorsObj(clearError(errorsObj,'duplicateName'))}
                                           onBlur={() => setErrorsObj(checkLength(errorsObj, 'duplicateName', duplicateName, 1, 100))}
                                    />
                                    <label htmlFor="create-project-input" className="text-gray-30 position-absolute mb-0">
                                        {t('enterNameOfSelection')}
                                    </label>
                                    {errorsObj.duplicateName &&
                                        <span className="error-msg position-absolute">
                                            {errorsObj.duplicateName === 'required' ?
                                                t('notBeEmpty')
                                                : errorsObj.duplicateName === 'back' ?
                                                    t('fakeSelectionName')
                                                    :errorsObj.duplicateName === 'min' ?
                                                        t('enterMin1')
                                                        :
                                                        t('max100')
                                            }
                                        </span>
                                    }
                                    {errorsObj.project &&
                                        <span className="error-msg position-absolute">
                                            {errorsObj.project === 'required' && t('selectProject')}
                                        </span>
                                    }
                                    {errorsObj.customer &&
                                        <span className="error-msg position-absolute">
                                            {errorsObj.customer === 'required' && t('choicePartner')}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-end mt-md-5 pt-md-3">
                            <div className="btn btn-outline" data-dismiss="modal">{t('cancelWord')}</div>
                            <div className="btn btn-primary position-relative" onClick={!isRequest ? createDuplicate : undefined}>
                                {isRequest && <LoaderBtn/>}
                                ОК
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
