import React, {useEffect, useState, useTransition} from "react";
import {Link, useHistory} from "react-router-dom";
import DiscountBonusInput from "../pages/cabinet/partials/DiscountBonusInput";
import QuantityInput from "../pages/cabinet/partials/QuantityInput";
import * as clipboard from "clipboard-polyfill";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    duplicateSelectionData,
    getCartSelection,
    getSelectedCustomerProjects,
    removeFromCart,
    updateSelectedPartner,
    updateSelectionsPage
} from "../../actions";
import {setSelectionDataForPdf} from "../../helper";
import {useTranslation} from "react-i18next";
import ProductBalance from "./ProductBalance";
import $ from 'jquery';
import {API_URL, API_URL_IMAGE} from "../../constant";
import axios from '../../axiosInstance';

export default function CartSelection() {
    const { t, i18n } = useTranslation();
    const [isRequest, setIsRequest] = useState(true);
    const [cartData, setCartData] = useState({});
    const [groupedProducts, setGroupedProducts] = useState([]);
    const [copyBtn, setCopyBtn] = useState(false);
    const [currencyToRender, setCurrencyToRender] = useState('');
    const selectedGlobalPartner = useSelector(state => state.selectedOptions.globalPartner, shallowEqual);
    const selectedGlobalProject = useSelector(state => state.selectedOptions.globalProject, shallowEqual);
    const selectedGlobalSelection = useSelector(state => state.selectedOptions.globalSelection, shallowEqual);
    const isDishesUser = useSelector(state => state.getDirectionsData.isDishesUser, shallowEqual);
    const managerId = useSelector(state => state.getManagerId.managerId, shallowEqual);
    const cartSelection = useSelector(state => state.cartSelection, shallowEqual);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        function getCart() {
            selectedGlobalSelection.id !== undefined && dispatch(getCartSelection(selectedGlobalProject.id, selectedGlobalSelection.id));
        }

        getCart();
        i18n.on('languageChanged', getCart);

        return () => i18n.off('languageChanged', getCart);
    }, []);

    useEffect(() => {
        $('#cart-selection-modal').modal('hide');
    }, [history.location]);

    useEffect(() => {
        if(cartSelection.counter > 0) {
            setCartData(cartSelection.cartSelection);
            setGroupedProducts(cartSelection.cartSelection.products);
        } else {
            setGroupedProducts([]);
        }
    }, [cartSelection.cartSelection])

    useEffect(() => {
        if (groupedProducts.length > 0) {
            setCurrencyToRender(groupedProducts[0].items[0].attributes.currency.toLowerCase())
        }
    }, [cartSelection])

    function copyOrderLink(copyLink) {
        clipboard.writeText(copyLink);
        setCopyBtn(true);
        setTimeout(() => {setCopyBtn(false)}, 2000);
    }

    function approveSelection() {
        const data = {
            id: selectedGlobalSelection.id,
            project_id: selectedGlobalProject.id,
            status: 2
        }

        axios.patch(`${API_URL}/front/selection/change_state`, data)
            .then(res => {
                // if selection approved, update selected customer project
                dispatch(getCartSelection(selectedGlobalProject.id, selectedGlobalSelection.id));
                // if selection approved, remove selected project and selection in updateSelectedPartner function
                dispatch(updateSelectedPartner({id: selectedGlobalPartner.id, name: selectedGlobalPartner.name}));
                // if selection approved, update selected customer project
                dispatch(getSelectedCustomerProjects(selectedGlobalPartner.id, managerId));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <div id="cart-selection-modal" className="modal fade pr-0" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header align-items-center py-3 px-4">
                            <div className="cart-selection-cap">
                                <h5 className="mb-0 semi-bold">{t('selectionWord')}</h5>
                                {selectedGlobalSelection.name !== undefined &&
                                    <div className="d-flex align-items-center">
                                        <span>{selectedGlobalProject.name}</span>
                                        <Link title={t('goToSelectionPage')}
                                              to={`/cabinet/selection?${selectedGlobalProject.id}-${selectedGlobalSelection.id}`}
                                              className="selection-link d-flex align-items-center"
                                              onClick={() => {
                                                if(history.location.pathname === '/cabinet/selection') {
                                                    dispatch(updateSelectionsPage(true));
                                                    $('#cart-selection-modal').modal('hide');
                                                }
                                              }
                                        }>
                                           {selectedGlobalSelection.name}
                                            <svg className="svg-icon ml-1">
                                                <use xlinkHref="/images/sprite.svg#link-icon"></use>
                                            </svg>
                                        </Link>
                                    </div>
                                }
                            </div>
                            <svg className="svg-icon pointer" data-dismiss="modal">
                                <use xlinkHref="/images/sprite.svg#close"></use>
                            </svg>
                        </div>
                        <div className="modal-body custom-scroll py-2 px-4">
                            <div className="modal-order-wrap">
                                {groupedProducts.length > 0 ?
                                    groupedProducts.map((selection, i) => {
                                        return (
                                            <div key={`category-wrap-${i}`} className="selection-products__wrap mb-3">
                                                <div className="selection-products__category">{selection.name}</div>
                                                <div>
                                                    {selection.items.map((product, j) => {
                                                        const isMarkdown = product.attributes.parent_id !== null ? 'markdown/' : '';

                                                        return (
                                                            <div key={`product-wrap-${i}-${j}`} id={`product-item-${product.id}`} className="selection-products__item position-relative d-flex align-items-xl-center justify-content-between">
                                                                <div className="d-xl-flex justify-content-between align-items-center w-100 mr-xl-4">
                                                                    <div className="selection-products__item-col d-flex pr-4">
                                                                        <div className="selection-products__item-img position-relative d-flex align-items-center justify-content-center flex-shrink-0">
                                                                            <Link to={`/product/${isMarkdown}${product.attributes.slug}`} className="fake-link-block"></Link>
                                                                            <img src={`${API_URL_IMAGE}/${product.attributes.image}`} alt={product.name}/>
                                                                        </div>
                                                                        <div className="selection-products__item-name ml-3">
                                                                            <Link to={`/product/${isMarkdown}${product.attributes.slug}`} className="d-block text-decoration-none mb-1">{product.name}</Link>
                                                                            <div className="d-flex flex-wrap">
                                                                                <span className="mr-2">{t('artikulWord')}: {product.attributes.artikul}</span>
                                                                                {((product.attributes.balance != 0) && (product.attributes.balance != null) && (product.attributes.balance != 'none') && cartData.statusRaw != 3) &&
                                                                                    <span className="d-flex align-items-center justify-content-center mr-2">
                                                                                        {t('productBalance')}:
                                                                                        <ProductBalance balance={product.attributes.balance}/>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                            {product.attributes.action_code &&
                                                                                <div className="mt-1">
                                                                                    <span className="action-sticker d-inline-flex align-items-center">
                                                                                        <svg className="svg-icon mr-2">
                                                                                            <use xlinkHref="/images/sprite.svg#markdown-status-icon"></use>
                                                                                        </svg>
                                                                                        <span>{t('actionWord')}</span>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-items-center mt-2 pt-1 mt-xl-0 pt-xl-0">
                                                                        <div className="selection-products__item-inputs d-flex">
                                                                            {cartData.retrobonuses > 0 &&
                                                                                <DiscountBonusInput type="bonus"
                                                                                                    initValue={product.attributes.retrobonuses}
                                                                                                    productId={`${i}-${j}`}
                                                                                                    disabled={true}
                                                                                />
                                                                            }
                                                                            {!isDishesUser &&
                                                                                <DiscountBonusInput type="discount"
                                                                                                    initValue={product.attributes.discount}
                                                                                                    productId={`${i}-${j}`}
                                                                                                    disabled={true}
                                                                                />
                                                                            }
                                                                            <QuantityInput quantity={product.quantity}
                                                                                           productId={product.id}
                                                                                           selectionId={selectedGlobalSelection.id}
                                                                                           projectId={selectedGlobalProject.id}
                                                                                           disabled={cartData.statusRaw == 2 || product.attributes.parent_id !== null}
                                                                                           updated={(flag) => flag && dispatch(updateSelectionsPage(true))}
                                                                            />
                                                                        </div>
                                                                        <div className="selection-products__price text-right">
                                                                            {product.attributes.discount != 0 &&
                                                                                <span><span>{product.attributes.originTotalPrice}</span> {currencyToRender}</span>
                                                                            }
                                                                            <div>{product.attributes.totalPrice} {currencyToRender}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {cartData.statusRaw != 2 &&
                                                                    <svg className="delete-btn svg-icon pointer flex-shrink-0" onClick={() => dispatch(removeFromCart(product.id, selectedGlobalProject.id, selectedGlobalSelection.id))}>
                                                                        <use xlinkHref="/images/sprite.svg#delete-icon"></use>
                                                                    </svg>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="selection-products-empty d-flex align-items-center justify-content-center position-relative">
                                        <img src="/images/empty-bg.svg" className="position-absolute w-100 h-100" alt="empty-bg"/>
                                        <div className="position-relative text-center">
                                            {selectedGlobalSelection.name === undefined ?
                                                <div dangerouslySetInnerHTML={{__html: t('selectProjectAndSelection')}} />
                                            :
                                                <>
                                                    {t('addProductToSelection')}
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            {groupedProducts.length > 0 &&
                                <div className="modal-order-total text-right pt-2 mb-3 mb-md-0">
                                    <div className="semi-bold">{t('totalWord')}: {cartData.total} {currencyToRender}</div>
                                    {cartData.retrobonuses != 0 &&
                                        <div className="bonus-total mt-1 d-flex align-items-center justify-content-end">
                                            <span>{cartData.retrobonuses}</span>
                                            <svg className="svg-icon ml-2">
                                                <use xlinkHref="/images/sprite.svg#bonus-icon"></use>
                                            </svg>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {groupedProducts.length > 0 &&
                            <div className="modal-footer d-flex align-items-center justify-content-end flex-nowrap">
                                <div className="cart-dropdown dropdown">
                                    <div className="cart-dropdown__btn pointer" data-toggle="dropdown">
                                        <svg className="svg-icon">
                                            <use xlinkHref="/images/sprite.svg#setting-circles-icon"></use>
                                        </svg>
                                    </div>
                                    <div className="cart-dropdown__list dropdown-menu">
                                        {/*<div className="cart-dropdown__list-item d-flex align-items-center pointer" onClick={() => copyOrderLink(cartData.orderLink)}>
                                            <svg className="svg-icon flex-shrink-0 mr-2">
                                                <use xlinkHref="/images/sprite.svg#copy-icon-2"></use>
                                            </svg>
                                            <span>{copyBtn ? 'Ссылка скопирована' : 'Копировать ссылку'}</span>
                                        </div>*/}
                                        <div className="cart-dropdown__list-item d-flex align-items-center pointer position-relative"
                                             data-toggle="modal"
                                             data-target="#duplicate-selection-modal"
                                             data-dismiss="modal"
                                             onClick={() => dispatch(duplicateSelectionData(selectedGlobalSelection.id, selectedGlobalSelection.name))}
                                        >
                                            <svg className="svg-icon flex-shrink-0 mr-2">
                                                <use xlinkHref="/images/sprite.svg#folder-icon"></use>
                                            </svg>
                                            <span>{t('copySelection')}</span>
                                        </div>
                                        <div className={`cart-dropdown__list-item d-flex align-items-center pointer position-relative`}
                                             data-toggle="modal"
                                             data-target={isDishesUser ? '#setting-horeca-pdf-modal' : '#settings-modal'}
                                             data-dismiss="modal"
                                             onClick={() => {
                                                 setSelectionDataForPdf(selectedGlobalProject.id, selectedGlobalSelection.id, selectedGlobalSelection.name); // set selection data for generate PDF
                                             }} // set IDs for generate PDF
                                        >
                                            <svg className="svg-icon flex-shrink-0 mr-2">
                                                <use xlinkHref="/images/sprite.svg#pdf-icon"></use>
                                            </svg>
                                            <span>{t('saveInPdf')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}