import React, {Component, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import SideMenu from "./SideMenu";
import {getCompareCounter, getDirections, getExchangeCurrency} from "../actions";
import CabinetMenu from "./partials/CabinetMenu";
import PartnerDropdown from "./pages/cabinet/partials/PartnerDropdown";
import CreateProjectSelectionsModal from "./partials/CreateProjectSelectionsModal";
import CartSelectionCounter from "./partials/CartSelectionCounter";
import {DEFAULT_LOCALE} from "../constant";
import LangDropdown from "./partials/LangDropdown";
import axios from "../axiosInstance";
import ExchangeWidget from "./partials/ExchangeWidget";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {API_URL} from "../constant";
import HeaderDesktopMenu from "./partials/HeaderDesktopMenu";

export default function Header({compareCounter}) {
    const { t } = useTranslation();
    const [localeName, setLocalName] = useState(localStorage.getItem('userLocale') || DEFAULT_LOCALE);
    const sideMenuData = useSelector(state => state.getDirectionsData.menuData, shallowEqual);
    const selectedGlobalPartner = useSelector(state => state.selectedOptions.globalPartner, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDirections(selectedGlobalPartner.id));
        dispatch(getCompareCounter());

        const onLocaleChange = () => {
            dispatch(getDirections(selectedGlobalPartner.id));
        };

        i18n.on('languageChanged', onLocaleChange);

        return () => i18n.off('languageChanged', onLocaleChange);
    }, [selectedGlobalPartner.id]);

    useEffect(() => {
        dispatch(getExchangeCurrency());
        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <React.Fragment>
            <CreateProjectSelectionsModal/>
            <header className="bg-white position-fixed w-100">
                <div className="page-container">
                    <div className="d-flex align-items-center mt-md-3 mb-xxl-3">
                        <div className="d-flex align-items-center">
                            <SideMenu sideMenuData={sideMenuData}/>
                            <Link to="/" className="d-block mr-2">
                                <img src="/images/logo-mirs.svg" alt="MIRS logo" className="header-logo"/>
                            </Link>
                            <div className="d-none d-md-block mr-2">
                                <LangDropdown thisLocale={localeName} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end w-100">
                            <div className="header-wrapper d-flex align-items-center w-100">
                                <div className="header-wrapper__search-wrap d-flex">
                                    <HeaderSearch />
                                    <div className="select-block pr-xl-3">
                                        <PartnerDropdown type="header"/>
                                    </div>
                                </div>
                                <div className="d-none d-md-block flex-shrink-0">
                                    <ExchangeWidget />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="header-action pointer d-flex flex-column align-items-center position-relative">
                                    <Link to="/cabinet/favorites" className="fake-link-block" />
                                    <svg className="svg-icon">
                                        <use xlinkHref="/images/sprite.svg#favorite-icon"></use>
                                    </svg>
                                    <span className="header-action__text">{t('favoriteWord')}</span>
                                </div>
                                <div className="header-action pointer flex-column align-items-center position-relative dropdown-cabinet">
                                    <div className="d-flex flex-column align-items-center" data-toggle="dropdown">
                                        <svg className="svg-icon">
                                            <use xlinkHref="/images/sprite.svg#user"></use>
                                        </svg>
                                        <svg className="svg-icon">
                                            <use xlinkHref="/images/sprite.svg#my-data-icon"></use>
                                        </svg>
                                        <span className="header-action__text">
                                                {t('cabinetWord')}
                                            </span>
                                    </div>
                                    <div className="dropdown-cabinet-menu dropdown-menu dropdown-menu-right">
                                        <CabinetMenu />
                                    </div>
                                </div>
                                <div className="header-action pointer d-flex flex-column align-items-center position-relative">
                                    <Link to="/comparison-lists" className="fake-link-block" />
                                    <svg className="svg-icon">
                                        <use xlinkHref="/images/sprite.svg#libra"></use>
                                    </svg>
                                    {compareCounter > 0 && <div className="header-action__counter d-flex justify-content-center align-items-center semi-bold">{compareCounter}</div>}
                                    <span className="header-action__text">{t('compareWord')}</span>
                                </div>
                                <CartSelectionCounter/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <HeaderDesktopMenu />
        </React.Fragment>
    );
}