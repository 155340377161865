import React from "react";
import AuthHeader from "./AuthHeader";
import {isAuthorizedUser} from "../../helper";
import HeaderContainer from "../../containers/HeaderContainer";
import ZeroHeader from "../pages/main/partials/ZeroHeader";
import PublicHeader from "../PublicHeader";
import {useLocation} from "react-router-dom";

export default function HeadersWrapBlock() {
    const location = useLocation();
    const { pathname } = location;

    // В зависимости от статуса пользователя (логин/нелогин, хорека\бытовая) показываем нужный хедер
    if (["/login", "/reset-password", "/new-password"].includes(pathname)) {
        return <AuthHeader />;
    }

    if (isAuthorizedUser()) {
        return <HeaderContainer />;
    }

    // если пользователь не залоган, на главной странице показываем ZeroHeader
    return pathname === "/" ? <ZeroHeader /> : <PublicHeader />;
}