import React, {useEffect, useRef, useState} from "react";
import {checkLength, clearError, hiddenScroll, useOutsideClick} from "../../helper";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getCartSelection,
    getSelectedCustomerProjects, newProjectInDuplicate, updateCreateRenameModal,
    updateSelectedProject,
    updateSelectedSelection, updateSelectionsPage
} from "../../actions";
import {useHistory} from "react-router-dom";
import LoaderBtn from "./LoaderBtn";
import {useTranslation} from "react-i18next";
import $ from 'jquery';
import {API_URL} from "../../constant";
import axios from '../../axiosInstance';

export default function CreateProjectSelectionsModal() {
    const { t } = useTranslation();
    const modalData = useSelector(state => state.createRenameModal.data, shallowEqual);
    const type = modalData.type;
    const partnerId = modalData.partnerId;
    const projectId = modalData.projectId;
    const [inputValue, setInputValue] = useState('');
    const [errorsObj, setErrorsObj] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isRequest, setIsRequest] = useState(false);
    const selectedGlobalProject = useSelector(state => state.selectedOptions.globalProject, shallowEqual);
    const managerId = useSelector(state => state.getManagerId.managerId, shallowEqual);
    const modalRef = useRef();
    const dispatch = useDispatch();
    let history = useHistory();
    const inputRef = useRef();

    useEffect(() => {
        $('#create-modal').on('shown.bs.modal', () => {
            hiddenScroll(true);
            inputRef.current.focus();
        }).on('hidden.bs.modal', () => {
            hiddenScroll(false);
        })
    }, []);

    useOutsideClick(modalRef, () => {
        setIsOpen(false);
    });

    useEffect(() => {
        setInputValue('');
    }, [isOpen])

    useEffect(() => {
        setIsOpen(true);
        setInputValue('');
    }, [modalData]);

    const pressEnterRunSubmit = (e) => {
        (e.key === 'Enter' && !isRequest) && create();
    }

    function create() {
        // We don't need to validate form if any error present
        if(Object.keys(errorsObj).length > 0) return;

        const trimmedData = {
            inputValue: inputValue.trim(),
        };
        let errors = {};

        // We need to set required error if any field is empty
        Object.keys(trimmedData).map(key => {
            if(trimmedData[key].length === 0) errors[key] = 'required';
        });

        // Add required errors or send form
        if(Object.keys(errors).length > 0) {
            setErrorsObj(errors);
        } else {
            const url = `${API_URL}/front/selection`;
            let options = {};

            if(type === 'createProject' || type === 'createProjectInDuplicate') {
                options = {
                    method: 'post',
                    url: `${url}/project/store`,
                    data: {
                        name: inputValue.trim(),
                        customer_id: partnerId
                    }
                }
            } else {
                options = {
                    method: 'post',
                    url: `${url}/store`,
                    data: {
                        name: inputValue.trim(),
                        project_id: projectId
                    }
                }
            }

            setIsRequest(true);

            axios(options)
                .then(res => {
                    const response = res.data.data;

                    dispatch(getSelectedCustomerProjects(partnerId, managerId));
                    history.location.pathname == '/cabinet/selections' && dispatch(updateSelectionsPage(true));

                    if(type === 'createProject') {
                        dispatch(updateSelectedProject({id: response.project_id, name: response.name}));
                        dispatch(updateSelectedSelection({}));
                        dispatch(getCartSelection());

                        // open CreateSelectionModal after project created
                        dispatch(updateCreateRenameModal({
                            openModal: true,
                            type: 'createSelection',
                            partnerId: partnerId,
                            projectId: response.project_id
                        }));

                        $('#create-modal').modal('hide');
                        setTimeout(() => $('#create-modal').modal('show'), 500);
                    } else if(type === 'createProjectInDuplicate') {
                        $('#duplicate-selection-modal').modal('show');
                        dispatch(newProjectInDuplicate({id: response.project_id, name: response.name}));
                    } else {
                        dispatch(updateSelectedSelection({id: response.selection_id, name: response.name}));
                        dispatch(getCartSelection(selectedGlobalProject.id, response.selection_id))
                    }

                    type !== 'createProject' && $('#create-modal').modal('hide');
                    setIsRequest(false);
                    setIsOpen(false);

                    if(!res.data.success) {
                        setErrorsObj({inputValue: 'back'});
                    }
                })
                .catch(function (error) {
                    setErrorsObj({inputValue: 'back'});
                    setIsRequest(false);
                    console.log(error);
                });
        }
    }

    return (
        <div id="create-modal" className="selections-modal modal fade pr-0" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div ref={modalRef} className="modal-content">
                    {(isRequest && type === 'createProject') &&
                        <LoaderBtn bgColor={'rgba(255, 255, 255, 0.5)'} loaderBlue={true}/>
                    }
                    <div className="modal-header align-items-center py-3 px-4">
                        <h4 className="semi-bold text-ellipsis my-1 mb-0 mr-2">{t('creatingWord')} {(type === 'createProject' || type === 'createProjectInDuplicate') ? t('project2Word') : t('selection2Word')}</h4>
                        <svg className="svg-icon pointer" data-dismiss="modal" onClick={() => setIsOpen(false)}>
                            <use xlinkHref="/images/sprite.svg#close"></use>
                        </svg>
                    </div>
                    <div className="modal-body py-4 my-2 px-4">
                        <div className="floating-label mb-4 position-relative">
                            <input ref={inputRef}
                                   id="create-project-input"
                                   className={`w-100 ${inputValue !== '' ? 'has-value' : ''}`}
                                   type="text"
                                   value={inputValue}
                                   onChange={(e) => setInputValue(e.target.value)}
                                   onFocus={() => setErrorsObj(clearError(errorsObj,'inputValue'))}
                                   onBlur={() => inputValue !== '' && setErrorsObj(checkLength(errorsObj, 'inputValue', inputValue, (type === 'createProject' || type === 'createProjectInDuplicate') ? 3 : 1, 100))}
                                   onKeyDown={pressEnterRunSubmit}
                            />
                            <label htmlFor="create-project-input" className="text-gray-30 position-absolute mb-0">
                                {t('enterNameOf')} {(type === 'createProject' || type === 'createProjectInDuplicate') ? t('project2Word') : t('selection2Word')}
                            </label>
                            {errorsObj.inputValue &&
                                <span className="error-msg position-absolute">
                                    {errorsObj.inputValue === 'required' ?
                                        t('notBeEmpty')
                                        : errorsObj.inputValue === 'back' ?
                                            t('fakeNameOfProject')
                                            :errorsObj.inputValue === 'min' ?
                                                (type === 'createProject' || type === 'createProjectInDuplicate') ? t('enterMin3') : t('enterMin1')
                                                :
                                                t('max100')
                                    }
                                </span>
                            }
                        </div>
                        <div className="btn btn-primary w-100 position-relative" onClick={!isRequest ? create : undefined}>
                            {isRequest && <LoaderBtn/>}
                            {t('createWord')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
