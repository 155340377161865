import React, {useEffect, useRef, useState} from "react";
import {
    getCartSelection,
    updateCreateRenameModal, updateSelectedPartner,
    updateSelectedProject,
    updateSelectedSelection
} from "../../../../actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useOutsideClick} from "../../../../helper";
import {useTranslation} from "react-i18next";
import $ from 'jquery';

export default function SelectionsDropdown({ type, projectsData }) {
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const [selectionInput, setSelectionInput] = useState('');
    const [selectedProject, setSelectedProject] = useState({});
    const [isSelectionsOpened, setIsSelectionsOpened] = useState(false);
    const [stayOpen, setStayOpen] = useState(false);
    const selectedGlobalPartner = useSelector(state => state.selectedOptions.globalPartner, shallowEqual);
    const selectedGlobalProject = useSelector(state => state.selectedOptions.globalProject, shallowEqual);
    const selectedGlobalSelection = useSelector(state => state.selectedOptions.globalSelection, shallowEqual);
    const dispatch = useDispatch();
    const dropdownListRef = useRef()

    useEffect(() => {
        setSelectedProject({});
        setIsSelectionsOpened(false);
        setProjects(projectsData ? projectsData : []);
    }, [projectsData]);

    useEffect(() => {
        if(selectedGlobalProject.name !== undefined) {
            setSelectedProject(selectedGlobalProject);
        }
    }, [selectedGlobalProject]);

    useEffect(() => {
        if(selectedGlobalSelection.name !== undefined) {
            setSelectionInput(selectedGlobalSelection.name);
        } else {
            setSelectionInput('');
        }
    }, [selectedGlobalSelection]);

    useOutsideClick(dropdownListRef, () => {
        setStayOpen(false);
    });

    function resetSelection() {
        if(type === 'header') {
            setSelectionInput('');
            setSelectedProject({});
            setIsSelectionsOpened(false);
            dispatch(updateSelectedProject({}));
            dispatch(updateSelectedSelection({}));
            dispatch(getCartSelection());
        }
    }

    return (
        <div ref={dropdownListRef}
             id="selections-dropdown"
             className={`custom-dropdown dropdown position-relative ml-3 ${selectedGlobalPartner.id === undefined ? 'disabled' : ''}`}
        >
            {selectionInput !== '' &&
                <svg className="reset-btn svg-icon position-absolute"
                     onClick={resetSelection}
                >
                    <use xlinkHref="/images/sprite.svg#close"></use>
                </svg>
            }
            <div className="custom-dropdown__btn floating-label position-relative m-0 pointer"
                 onClick={() => selectedGlobalPartner.id !== undefined && setStayOpen(!stayOpen)}
            >
                <input id="selection-input"
                       className={`w-100 pointer text-ellipsis ${selectionInput !== '' ? 'has-value' : ''} ${selectionInput === '' ? 'default-padding' : ''}`}
                       type="text"
                       value={selectionInput}
                       placeholder={t('selectProjectAndSelection2')}
                       onChange={(e) => setSelectionInput(e.target.value)}
                       readOnly
                />
                <label htmlFor="selection-input" className="text-gray-30 position-absolute mb-0">
                   <span className="position-relative">{t('projectAndSelection')}</span>
                </label>
                <div className="custom-dropdown__arrow position-absolute"></div>
            </div>
            <div className={`custom-dropdown__list custom-scroll dropdown-menu w-100 ${stayOpen ? 'show' : ''}`}>
                <div className={isSelectionsOpened ? 'd-none' : 'd-block'}>
                    <div className="add-new pointer" onClick={() => {
                        dispatch(updateCreateRenameModal({
                            openModal: true,
                            type: 'createProject',
                            partnerId: selectedGlobalPartner.id,
                        }));
                        $('#create-modal').modal('show');
                    }}>{t('createNewProject')}</div>
                    {projects.length > 0 &&
                        projects.map((project, i) => {
                            if (project.status != 1) return;

                            return (
                                <div key={`custom-item-${i}`}
                                     className={`project-item custom-dropdown__item pointer text-ellipsis ${selectedGlobalProject.id == project.project_id ? 'selected-filter' : ''}`}
                                     onClick={() => {
                                         setIsSelectionsOpened(true);
                                         dispatch(updateSelectedProject({id: project.project_id, name: project.name, selections: project.selections}));
                                         setSelectedProject({id: project.project_id, name: project.name, selections: project.selections});
                                     }}
                                >
                                    {project.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`selections-list ${!isSelectionsOpened ? 'd-none' : 'd-block'}`}>
                    <div className="selections-list__back-btn custom-dropdown__item pointer text-ellipsis semi-bold"
                         onClick={() => setIsSelectionsOpened(false)}
                    >
                        {selectedProject.name && selectedProject.name}
                    </div>
                    <div className="add-new pointer" onClick={() => {
                        dispatch(updateCreateRenameModal({
                            openModal: true,
                            type: 'createSelection',
                            partnerId: selectedGlobalPartner.id,
                            projectId: selectedGlobalProject.id
                        }));
                        $('#create-modal').modal('show');
                    }}>{t('createNewSelection')}</div>
                    <div>
                        {selectedProject.selections !== undefined && selectedProject.selections.map((selection, j) => {
                            return (
                                <div key={`selection-list-${j}`}
                                     className={`custom-dropdown__item pointer text-ellipsis ${selectedGlobalSelection.id == selection.storage_id ? 'selected-filter' : ''}`}
                                     onClick={() => {
                                         setSelectionInput(selection.name);
                                         setStayOpen(!stayOpen);
                                         dispatch(getCartSelection(selectedGlobalProject.id, selection.storage_id))
                                         dispatch(updateSelectedSelection({id: selection.storage_id, name: selection.name}));
                                     }}
                                >
                                    {selection.name}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
