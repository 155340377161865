import React from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";

function DirectionDropdown({ isDirectionUpdated }) {
    const publicDirection = useSelector(state => state.publicDirectionReducer.userPublicDirection);
    const history = useHistory();
    const { t } = useTranslation();
    function changeDirection(direction) {
        localStorage.setItem('userDirection', direction);
        history.push(`/direction/${direction === 'BT'? 'bytovaya_tehnika' : 'posuda'}`);
        isDirectionUpdated && isDirectionUpdated(true);
    }

    return (
        <div className="lang-dropdown dropdown ml-md-1 mr-md-2 my-auto direction">
            <div className="lang-dropdown__btn pointer" data-toggle="dropdown">
                 { publicDirection === 'HORECA' ? t('directionHoreca') : t('directionBT') }
                <div className="custom-dropdown__arrow position-absolute"></div>
            </div>
            <div className="lang-dropdown__list dropdown-menu direction">
                <div className="lang-dropdown__list-item position-relative pointer"
                     onClick={() => changeDirection(publicDirection === 'HORECA' ? 'BT' : 'HORECA')}
                >
                    { publicDirection === 'HORECA' ? t('directionBT') : t('directionHoreca') }
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(connect()(DirectionDropdown));