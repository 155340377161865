import React, { useEffect } from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import LoadingData from "./LoadingData";
import { useDispatch } from "react-redux";
import {setAxiosSettings} from "../helper"
import {setPublicUserDirection} from "../actions";

const PublicProduct = ({ t, globalPartner, seoCallback }) => {
    const { directionSlug, slug } = useParams();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(['posuda','bytovaya_tehnika'].includes(directionSlug)) {
            let token = 'anonymous_bt';
            let direction = 'BT';
            if(directionSlug === 'posuda') {
                token='anonymous_horeca';
                direction = 'HORECA';
            }
            const locale = localStorage.getItem('userLocale') || 'ua';
            localStorage.setItem('authToken', token);
            localStorage.setItem('isHorecaUser', direction === 'HORECA');
            localStorage.setItem('userDirection', direction);
            dispatch(setPublicUserDirection(direction));
            setAxiosSettings(token, locale);
            history.push(`/product/${slug}${location.search ? `?${location.search}` : ''}`);
        }
    }, []);


    return (
        <React.Fragment>
            <LoadingData/>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        globalPartner: state.selectedOptions.globalPartner,
    }
};

export default PublicProduct;