import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../axiosInstance";
import { debounce } from "throttle-debounce";
import {PRICE_NEED_UPDATE} from "../actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {API_URL, API_URL_IMAGE} from "../constant";

class HeaderSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputVal: '',
			productData: {},
			categoryData: {},
			showResult: false,
			showMobileSearch: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.getSearchDataDebounced = debounce(500, this.getSearchData);
	}

	handleChange(e) {
		const inputVal = e.target.value;
		this.setState({inputVal}, () => {
			this.getSearchDataDebounced(this.state.inputVal);
        });
	}

	handleClear() {
		this.setState({
			inputVal: '',
			productData: {},
			categoryData: {},
			showResult: false,
            showMobileSearch: false
		})
	}

	handleBlur() {
		setTimeout(() => {
			this.setState({showResult: false});
		}, 200);
	}

	handleSubmit(e) {
        e.preventDefault();
        if(this.state.inputVal.trim() === '') return;
        document.getElementById('header-search-btn').click();
        document.activeElement.blur();
	}

	handleSearchClick(e) {
        if(this.state.inputVal.trim() === '') e.preventDefault();

        // Mobile behaviour
		if(window.innerWidth < 1366 && !this.state.showMobileSearch) {
            e.preventDefault();
            this.setState({showMobileSearch: true});
		}

	}

	getSearchData(query) {
        if(query === '') {
            this.setState({
                productData: {},
                categoryData: {},
                showResult: false
            })
		} else {
			const customerId = this.props.globalPartner.id !== undefined ? `customer_id=${this.props.globalPartner.id}` : '';

			axios.get(`${API_URL}/front/search-tips?query=${encodeURIComponent(query)}&${customerId}`)
				.then(res => {
					const searchResult = document.getElementById('header-search-result');
					if(searchResult.classList.contains('no-result')) {
                        searchResult.classList.remove('no-result');
					} else {
						const productData = res.data.productsData;
						const categoryData = res.data.categoriesData;
						const showResult = true;

						this.setState({productData, categoryData, showResult});
                    }
				})
				.catch(function (error) {
					console.log(error);
				});
        }
	}

	render() {
		const { t } = this.props;

		return (
			<div id="header-search-wrapper"
				 className={`position-relative w-100 ${this.state.showMobileSearch ? 'search-opened-mobile' : ''}`}
			>
                <form onSubmit={this.handleSubmit}>
					{this.state.showMobileSearch &&
                    	<span id="header-search-mobile-close"
							  className="position-absolute"
							  onClick={this.handleClear}
						>
							<svg className="svg-icon">
								<use xlinkHref="/images/sprite.svg#close"></use>
							</svg>
						</span>
					}
					<input id="header-search"
						   name="search-input"
						   type="text"
						   className="w-100 border border-primary rounded"
						   placeholder={t('search')}
						   value={this.state.inputVal}
						   onChange={this.handleChange}
						   onBlur={this.handleBlur}
						   autoComplete="off"
					/>
					{this.state.inputVal !== '' &&
						<span id="header-search-clear"
							  className="d-none d-md-inline position-absolute pointer"
							  onClick={this.handleClear}
						>
							<svg className="svg-icon">
								<use xlinkHref="/images/sprite.svg#close"></use>
							</svg>
						</span>
					}
					<Link id="header-search-btn"
						  to={`/search?query=${encodeURIComponent(this.state.inputVal.trim())}`}
						  className="btn bg-primary position-absolute"
						  onClick={this.handleSearchClick}
					>
						<svg className="svg-icon">
							<use xlinkHref="/images/sprite.svg#search"></use>
						</svg>
					</Link>
					<div id="header-search-result"
						 className={`d-none position-absolute rounded ${this.state.showResult ? 'd-md-block' : ''}`}
					>
						{(Object.keys(this.state.productData).length > 0 || Object.keys(this.state.categoryData).length > 0) ?
							<React.Fragment>
								<div className="search-products">
									{Object.keys(this.state.productData).map((key, index) => {
										const productItem = this.state.productData[key];
										return (
											<div key={index} className="search-products__item d-flex position-relative">
												<Link to={`/product/${productItem.parent_id !== null ? 'markdown/' : ''}${productItem.productSlug}`}
													  className="position-absolute w-100 h-100"
												></Link>
												<div className="search-products__item-img flex-shrink-0">
													<img src={`${API_URL_IMAGE}/${productItem.previewImage}`}/>
												</div>
												<div className="ml-2 pl-1">
													<div className="mb-1">{productItem.productName}</div>
													<div>
														{productItem.price && Number.parseFloat(productItem.price) > 0 &&
														<span className="search-products__item-price d-inline-block font-weight-bold pr-1">
															{productItem.price} {productItem.currency.toLowerCase()}.
														</span>
														}
														{productItem.color != null ?
															<span className="search-products__item-color pr-1">{t('colorWord')}: <span>{productItem.color}</span></span>
															:
															''
														}
														{productItem.parent_id !== null &&
															<span className="markdown-sticker d-inline-flex align-items-center mr-2">
																<svg className="svg-icon mr-2">
																	<use xlinkHref="/images/sprite.svg#markdown-status-icon"></use>
																</svg>
																<span>{t('markdownWord')}</span>
															</span>
														}
														{productItem.action_code !== null &&
															<span className="action-sticker d-inline-flex align-items-center">
																<svg className="svg-icon mr-2">
																	<use xlinkHref="/images/sprite.svg#markdown-status-icon"></use>
																</svg>
																<span>{t('actionWord')}</span>
															</span>
														}
													</div>
												</div>
											</div>
										);
									})}
								</div>
								<div className="search-category mt-4">
									<div className="text-gray pl-3" style={{marginBottom: '6px'}}>{t('goToCat')}</div>
									{Object.keys(this.state.categoryData).map((key, index) => {
										return (
											<Link key={index}
												  to={`/catalog/${key}`}
												  className="d-flex no-decoration"
											>
												{this.state.categoryData[key]}
											</Link>
										);
									})}
								</div>
							</React.Fragment>
							:
							<div className="text-black px-3 pt-3">{t('nothingWasFoundAccordingToYourRequest')}</div>
						}
					</div>
                </form>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		globalPartner: state.selectedOptions.globalPartner,
	}
};

export default withTranslation()(connect(mapStateToProps)(HeaderSearch));
