import React from "react";

export default function ProductBalance({balance}) {
    return (
        <>
            {((balance != 0) && (balance != null) && (balance != 'none')) &&
                <>
                    {balance === 'many' ?
                        <span className="product-balance many">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        : balance === 'small' ?
                            <span className="product-balance small">
                                <span></span>
                            </span>
                            : <span className="product-balance quantity">{balance} шт</span>
                    }
                </>
            }
        </>
    );
}
