import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function HeaderMenuItemsInner({menuData, currentTab}) {
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        setActiveTab(currentTab);
    }, [currentTab]);

    return (
        <div className="subcategories-menu bg-white w-100">
            {menuData.map((category, k) => {
                const isMarkdown = category.slug === 'markdown' ? `markdown/` : '';
                return (
                    <Fragment key={`category-inner-${k}`}>
                        {category.children.length > 0 && category.children.map((subCategory, g) => {
                            return (
                                <div key={`subcategory-list-${g}`}
                                     className={`${activeTab == subCategory.id ? 'd-block' : 'd-none'}`}
                                >
                                    <h4 className="mb-4">
                                        <Link to={`/section/${isMarkdown}${subCategory.slug}`} className="text-decoration-none semi-bold">
                                            {subCategory.name}
                                        </Link>
                                    </h4>
                                    <div className="subcategories-menu__list">
                                        {subCategory.children.length > 0 && subCategory.children.map((item, h) => {
                                            return (
                                                <div key={`subcategory-child-${k}-${g}-${h}`} className="subcategories-menu__list-item">
                                                    <Link to={`/catalog/${isMarkdown}${item.slug}`} className="text-decoration-none semi-bold">
                                                        {item.name}
                                                    </Link>
                                                    {item.children.length > 0 && item.children.map((subItem, y) => {
                                                        return (
                                                            <Link key={`sub-item-key-${y}`} to={`/catalog/${isMarkdown}${item.slug}?subcategory=${subItem.slug}`} className="text-decoration-none">
                                                                {subItem.name}
                                                            </Link>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </Fragment>
                )
            })}
        </div>
    );
}