import React from "react";
import {isAuthorizedUser} from "../../helper";
import {useLocation} from "react-router-dom";
import LiftUp from "../LiftUp";
import CartSelection from "./CartSelection";
import DuplicationSelectionModal from "./DuplicationSelectionModal";
import SettingsModal from "../SettingsModal";
import Footer from "../Footer";
import SettingsPDFHorecaModal from "../PDFHoreca/SettingsPDFHorecaModal";
import AuthFooter from "./AuthFooter";
import ZeroFooter from "../pages/main/partials/ZeroFooter";

export default function FootersWrapBlock() {
    const { pathname } = useLocation();
    // В зависимости от статуса пользователя (логин/нелогин, хорека\бытовая) показываем нужный футер
    if (isAuthorizedUser()) {
        return (
            <>
                {/*Только для залоганного*/}
                <LiftUp isInner={false} />
                <CartSelection />
                <DuplicationSelectionModal />
                <SettingsModal />
                <SettingsPDFHorecaModal />
                <Footer />
            </>
        );
    }

    if (["/login", "/reset-password", "/new-password"].includes(pathname)) {
        return <AuthFooter />;
    }

    // если пользователь не залоган, на главной странице показываем ZeroFooter
    return pathname === "/" ? <ZeroFooter /> : <Footer />;
}